const BASE_URL = "/extra";
// const BASE_URL = "http://127.0.0.1:8080/dist";

function promiseGet(url) {
  return new Promise((resolve, reject) => {
    $.ajax({
      method: "get",
      dataType: "json",
      url,
      success: resolve,
      error: reject,
    });
  });
}

function loadScript(url) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");
    script.onload = resolve;
    script.onerror = reject;
    script.async = false
    script.text = "text/javascript"
    script.src = url;
    document.getElementsByTagName("body")[0].appendChild(script);
  });
}

function loadExternal(page, options, selector) {
  return promiseGet(`${BASE_URL}/manifest.json?_t=${Date.now()}`)
    .then((res) => {
      console.log(res);
      return res[`${page}.js`];
    })
    .then((scriptUrl) => {
      return loadScript(`${BASE_URL}/${scriptUrl}`);
    })
    .then(() => {
      return new Vue(
        Object.assign(
          {},
          {
            render: (h) => h(window[`__${page}__`].default),
          },
          options
        )
      ).$mount(selector || `#${page}-replacement`);
    });
}

function loadExternalBase(page) {
  return promiseGet(`${BASE_URL}/manifest.json?_t=${Date.now()}`)
    .then((res) => {
      return res[`${page}.js`];
    })
    .then((scriptUrl) => {
      return loadScript(`${BASE_URL}/${scriptUrl}`);
    })
    .then(() => {
      console.log(window[`__${page}__`])
      return window[`__${page}__`].default;
    });
}

function loadExternalCommonChunk() {
  return promiseGet(`${BASE_URL}/manifest.json?_t=${Date.now()}`).then(
    (res) => {
      console.log(res);
      return Promise.all([
        loadScript(`${BASE_URL}/${res["chunk-common.js"]}`),
        loadScript(`${BASE_URL}/${res["chunk-vendors.js"]}`),
        loadScript(`${BASE_URL}/${res["antd.style.js"]}`),
      ])
      ;
    }
  );
}
module.exports = {
  loadExternal,
  loadExternalCommonChunk,
  loadExternalBase,
};
