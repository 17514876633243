(function () {
  const { fetchStrategy } = require("../api/acceptGroup")
  const dkAcceptGroup = require('dk-accept-group-cascader')

  /**
   * @param {toUrl} 需要跳转的url
   * @param {name} 跳转项目的名称，防止打开两个页面
   * @param {interval} 发送消息的间隔 默认 500
   * @param {payload} //参数
  */
  const handleF2eToOther = (toUrl, name, interval = 500, payload) => {
    // http://localhost:8080/#/?from=F2E 商车Url
    if (
      location.href.indexOf("fat") >= 0 ||
      location.href.indexOf("carrierglobe") >= 0
    ) {
      let timerContainer = null;
      const token = localStorage.getItem("iam-token");
      const vehWindow = window.open(toUrl, name);
      let tepValues = { token: token };
      if (payload) {
        tepValues = { ...tepValues, ...payload };
      }
      const messageValue = JSON.stringify(tepValues);
      let messageIndex = 0; //发送消息次数
      const handlePostMessage = () => {
        // console.log('handlePostMessage')
        if (messageIndex < 3) {
          if (vehWindow) {
            vehWindow.postMessage(messageValue, toUrl);
          }
          messageIndex += 0.5;
        } else {
          clearInterval(timerContainer);
          timerContainer = null;
        }
      };
      timerContainer = setInterval(handlePostMessage, interval);
      return;
    }
  };

  if (!window.esapp) {
    window.esapp = {}
  }

  window.esapp.route = {
    change: function (hash, config, args) {
      var id = _.first(_.words(hash, /[^/]+/g));
      var orderId = null
      var orderNo = null
      if (hash.split('/:').length > 1) {
        var hashId = _.last(_.words(hash, /[^/:]+/g)).split('|');
        orderId = hashId[0]
        orderNo = decodeURIComponent(hashId[1])
      }
      var page = window.esapp.pages[id];

      if (!!orderId) {
        id = id + "_" + orderId
      }

      if (!page) {
        var list = id.split('-')
        var oriId = []
        for (var i = 0; i < list.length - 1; i++) {
          oriId.push(list[i])
        }
        page = window.esapp.pages[oriId.join('-')]
      }
      if (!page) {
        return
      }
      if (!config) {
        config = {
          single: true
        }
      }
      var js = page.js
      var html = page.html
      var css = page.css
      var title = page.title

      // var currentH = $('#' + id)
      if ($('#' + id).length > 0) {
        $('#shell-tabs a[href="#' + id + '"]').tab('show');
        $('.shell-style').attr('media', 'print');
        var currentS = $('#' + id + '-style');
        if (currentS.length > 0) {
          currentS.attr('media', 'screen')
        }
        return;
      }
      if (!config.single) {

        // tab contant
        $('#shell-contents').append(Handlebars.compile($('#shell-content').html())({
          id: id,
          value: hash
        }))

        // tab
        $('#shell-tabs').append(Handlebars.compile($('#shell-tab').html())({
          id: id,
          title: title + (!!orderNo ? ("-" + orderNo) : ""),
          value: hash
        }))

        // breadcrumb
        var parent = _.clone(page.parentTitle)
        if (!parent) {
          parent = []
        }
        parent.push({
          title: page.title
        })
        $('#' + id).prepend(Handlebars.compile($('#shell-breadcrumb').html())(parent))

        // add iframe
        var iframe = $('<iframe class="tab-iframe" src="' + '/html' + html + '"></iframe>')
        if (config.target && config.target === '_blank') {
          $('#page-wrapper').remove();
          $('#shell-tabs').remove();
          $('#side-menu').remove();

          $('#iframe').eq(0).append(iframe);
          $('#iframe .tab-iframe').load(function () {
            var iframeHeight = $(this).contents().height()
            $(this).height(iframeHeight + 'px')
            $(this).css({
              width: '100%'
            });
          })
        } else {
          $('#' + id + ' .tab-content').eq(0).append(iframe)
          $('#' + id + ' .tab-iframe').load(function () {
            var iframeHeight = $(document.body).height() - $(this).offset().top - 60 // 外层容器的padding值
            console.log($(this).contents().height())
            $(this).height(iframeHeight + 'px')
          })

          $('#shell-tabs a[href="#' + id + '"]').tab('show')
        }
      } else {
        // load css
        $('.shell-style').attr('media', 'print')
        var currentS = $('#' + id + '-style')
        if (currentS.length > 0) {
          currentS.attr('media', 'screen')
        }
        // load html
        var currentH = $('#' + id)
        if (currentH.length > 0) {
          $('#shell-tabs a[href="#' + id + '"]').tab('show')
        } else if (html) {
          $('#shell-contents').append(Handlebars.compile($('#shell-content').html())({
            id: id
          }))

          $.ajax({
            type: 'get',
            url: '/html' + html,
            cache: false, //不缓存
            data: {},
            success: function (res) {
              var arr = res.split('<script type="text/javascript"')
              $('#' + id + ' .tab-content').eq(0).html(arr[0])

          // breadcrumb
          var parent = _.clone(page.parentTitle)
          if (!parent) {
            parent = []
          }
          parent.push({
            title: page.title
          })
          $('#' + id).prepend(Handlebars.compile($('#shell-breadcrumb').html())(parent))

          $('#shell-tabs').append(Handlebars.compile($('#shell-tab').html())({
            id: id,
            title: title,
            value: hash
          }))
          setTimeout(function () {
            $('#shell-tabs a[href="#' + id + '"]').tab('show');
            if ($('#shell-tabs #control-center-tab').find('.shell-tab-remove').length > 0) $('#shell-tabs #control-center-tab').find('.shell-tab-remove').remove();
          }, 500)

          // load javascript only add page
          $('#shell-tabs a[href="#' + id + '"]').on('shown.bs.tab', function (e) {
            window.esapp.route.current = id;
            //点的快了，会显示两个页面   此处关闭一个。
            if ($('.tab-pane.fade.portlet.light.portlet-fit.in.active').eq(1).length) {
              $('.tab-pane.fade.portlet.light.portlet-fit.in.active').eq(0).removeClass("active")
            }
            if (window.esapp.route.isHomepage) {
              location.hash = window.esapp.route.isHomepage;
              window.esapp.route.isHomepage = ''
            }

            //load scope
            var loadScope = function (scope, id) {
              if (scope.preference && scope.preference.appId) {
                if ($(id).data('vue_inited')) {
                  scope.init(id);
                  return
                }
                _.ui.mask()
                _.util.ajax_get("/table/queryTableBusiness", {
                  tableName: scope.preference.table[0]
                }, function (res) {
                  _.ui.unmask()
                  res.tablesMap = {}
                  for (var i = 0; i < res.tables.length; i++) {
                    res.tablesMap[res.tables[i].columnKey] = res.tables[i]
                  }
                  var table = $('[esapp-id=' + scope.preference.appId + '] .table-scrollable')
                  var needUpdateSetting = false
                  var allTables = []

                  var sort = function (wrapper, selector, sortConfig, configList, thCols) {
                    var cols = []
                    var colsData = []
                    var dataColumn = []
                    for (var i = 0; i < selector.length; i++) {
                      var element = selector.eq(i)
                      if (thCols) {
                        dataColumn = thCols[i]
                      } else {
                        dataColumn = element.attr('data-colum') || element.attr('data-colum-name')
                      }

                      colsData.push(dataColumn)

                      var c = sortConfig[dataColumn]

                      if (dataColumn && !c) {
                        needUpdateSetting = true
                        var columnName = element.attr('data-colum-name') ? element.attr('data-colum-name') : element.text()

                        if( id === "#security-incident" && (columnName === '最新进展' ||
                        columnName === '车辆状态' ||
                        columnName === '业务类型' ||
                        columnName === '客服' ||
                        columnName === '客户' ||
                        columnName === '堆场' ||
                        columnName === '门点' ||
                        columnName === '港区') ) {
                          c = {
                            columnKey: dataColumn,
                            columnName: columnName,
                            needDisplay: false,
                            needExport: true,
                            seq: i + 1
                          }

                        }else{
                          c = {
                            columnKey: dataColumn,
                            columnName: columnName,
                            needDisplay: true,
                            needExport: true,
                            seq: i + 1
                          }
                        }
                        sortConfig[dataColumn] = c
                      }

                      // 如果data-colum-name和存储的不同（变化），更新列名
                      if (dataColumn && c && element.attr('data-colum-name') && c.columnName !== element.attr('data-colum-name')) {
                        console.log('Update Setting colum name change ============================================>')
                        c = {
                          columnKey: dataColumn,
                          columnName: element.attr('data-colum-name') || element.text(),
                          needDisplay: true,
                          needExport: true,
                          seq: i + 1
                        }
                        sortConfig[dataColumn] = c
                        needUpdateSetting = true
                      }

                      if (c && configList) {
                        configList.push(c)
                      }

                      if (!c || c.needDisplay) {
                        if (!c) {
                          element.seq = i - 0.5
                          // 固定某列在左或者右，在th标签上加data-colum-fix-position="right" / "left"(未扩展，如有需要自行在里面加)
                          if (element.attr('data-colum-fix-position')) {
                            if (element.attr('data-colum-fix-position') === 'right') {
                              element.seq = selector.length
                            }
                          }
                        } else {
                          element.seq = c.seq
                          if (c.width) {
                            element.width(+c.width)
                          }
                        }
                        var d = [element]
                        if ((i + 1) < selector.length && selector.eq(i + 1) && selector.eq(i + 1).attr('v-else') === '') {
                          d.push(selector.eq(i + 1))
                          i++
                        }
                        cols.push(d)
                      } else {
                        element.remove()
                      }

                    }

                    cols.sort(function (colA, colB) {
                      return colA[0].seq - colB[0].seq
                    });

                    cols.forEach(function (v) {
                      v.forEach(function (element) {
                        wrapper.append(element)
                      });
                    })

                    return colsData
                  }


                  if (!scope.__th) {
                    scope.__th = table.find('th').clone()
                  }
                  if (!scope.__td) {
                    scope.__td = table.find('td').clone()
                  }
                  table.find('th').remove()
                  table.find('td').remove()

                  var thCols = sort(table.find('thead tr'), scope.__th.clone(), res.tablesMap, allTables)

                  sort(table.find('tbody tr'), scope.__td.clone(), res.tablesMap, null, thCols)

                  if (needUpdateSetting) {
                    _.ui.mask()
                    _.util.ajax_submit("/table/setTableBusiness", [{
                        tableName: scope.preference.table[0],
                        tables: allTables
                      }],
                      function (res) {
                        _.ui.unmask();
                        scope.init(id);
                        $(id).data('vue_inited', 'inited')
                      });
                  } else {
                    scope.init(id);
                    $(id).data('vue_inited', 'inited')
                  }
                })
              } else {
                scope.init(id);
                $(id).data('vue_inited', 'inited')
              }
            }



            var loadErrorCallBack = function (err) {
              if (err.message && err.message.indexOf('Loading chunk') > 0) {
                alert("版本已更新，请确定之后刷新页面！")
                //location.reload()
                // alert(typeof err)
                // alert(err.message)
                // alert(typeof err.message)
                // alert(JSON.stringify(err))
              }
            }
            try {
              _hmt.push(['_trackEvent', "PV", id])
            } catch (error) {
              console.log("百度统计:", error)
            }
            switch (id) {
              //工作台
              case 'control-center':
                require.ensure([], function (require, exports, module) {
                  var scope = require('control-center');
                  loadScope(scope, '#control-center');
                }, loadErrorCallBack, 'control-center');
                break;

               //工作台-旧
              case 'dashboard':
                require.ensure([], function (require, exports, module) {
                  var scope = require('dashboard');
                  loadScope(scope, '#dashboard');
                }, loadErrorCallBack, 'dashboard');
                break;

              case 'new-order-inquiry':
                require.ensure([], function (require, exports, module) {
                  var scope = require('new-order-inquiry');
                  loadScope(scope, '#new-order-inquiry');
                }, loadErrorCallBack, 'new-order-inquiry');
                break;

              // 准点到达审核
              case 'on-time-address-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('on-time-address-audit');
                  loadScope(scope, '#on-time-address-audit');
                }, loadErrorCallBack, 'on-time-address-audit');
                break;
               // 可带货业务
               case 'hitch-carry-on':
                require.ensure([], function (require, exports, module) {
                  var scope = require('hitch-carry-on');
                  loadScope(scope, '#hitch-carry-on');
                }, loadErrorCallBack, 'hitch-carry-on');
                break;
              // 路改水业务
              case 'land-transform-water':
                require.ensure([], function (require, exports, module) {
                  var scope = require('land-transform-water');
                  loadScope(scope, '#land-transform-water');
                }, loadErrorCallBack, 'land-transform-water');
                break;
              //路改水业务-详情
              case 'land-transform-water-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('land-transform-water-detail');
                  loadScope(scope, '#land-transform-water-detail');
                }, loadErrorCallBack, 'land-transform-water-detail');
                break;
              //确认陆改水-详情
              case 'land-transform-water-detail-confirm':
                require.ensure([], function (require, exports, module) {
                  var scope = require('land-transform-water-detail-confirm');
                  loadScope(scope, '#land-transform-water-detail-confirm');
                }, loadErrorCallBack, 'land-transform-water-detail-confirm');
                break;
              //路改水业务-配置
              case 'land-transform-water-config':
                require.ensure([], function (require, exports, module) {
                  var scope = require('land-transform-water-config');
                  loadScope(scope, '#land-transform-water-config');
                }, loadErrorCallBack, 'land-transform-water-config');
                break;
              // 设置线路必经点
              case 'driver-line-point':
                require.ensure([], function (require, exports, module) {
	                var scope = require('driver-line-point');
                  loadScope(scope, '#driver-line-point');
                }, loadErrorCallBack, 'driver-line-point');
              break;
              // 可带货列表
              case 'available-for-delivery':
                require.ensure([], function (require, exports, module) {
                  var scope = require('available-for-delivery');
                  loadScope(scope, '#available-for-delivery');
                }, loadErrorCallBack, 'available-for-delivery');
                break;
              // 处理任务 
              case 'handle-tasks':
                require.ensure([], function (require, exports, module) {
                  var scope = require('handle-tasks');
                  loadScope(scope, '#handle-tasks');
                }, loadErrorCallBack, 'handle-tasks');
                break;
              // 定价和销售核实任务
              case 'verification-tasks':
                require.ensure([], function (require, exports, module) {
                  var scope = require('verification-tasks');
                  loadScope(scope, '#verification-tasks');
                }, loadErrorCallBack, 'verification-tasks');
                break;
              // 售价和销售核实任务
              case 'verification-tasks-sale':
                require.ensure([], function (require, exports, module) {
                  var scope = require('verification-tasks-sale');
                  loadScope(scope, '#verification-tasks-sale');
                }, loadErrorCallBack, 'verification-tasks-sale');
                break;
              // 线路配置
              case 'maintenance-line':
                require.ensure([], function (require, exports, module) {
                  var scope = require('maintenance-line');
                  loadScope(scope, '#maintenance-line');
                }, loadErrorCallBack, 'maintenance-line');
                break;
              // 驳船任务
              case 'multimodal-transport':
                require.ensure([], function (require, exports, module) {
                  var scope = require('multimodal-transport');
                  loadScope(scope, '#multimodal-transport');
                }, loadErrorCallBack, 'multimodal-transport');
                break;
              // 费用指令
              case 'expense-instruction':
                require.ensure([], function (require, exports, module) {
                  var scope = require('expense-instruction');
                  loadScope(scope, '#expense-instruction');
                }, loadErrorCallBack, 'expense-instruction');
                break;
                //申请退单
              case 'apply-refund':
                require.ensure([], function (require, exports, module) {
                  var scope = require('apply-refund');
                  loadScope(scope, '#apply-refund');
                }, 'apply-refund');
                break;
                //司机账单管理
              case 'driver-bill-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-bill-management');
                  loadScope(scope, '#driver-bill-management');
                }, loadErrorCallBack, 'driver-bill-management');
                break;
                // 供应商账单管理
              case 'supplier-bill-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-bill-management');
                  loadScope(scope, '#supplier-bill-management');
                }, loadErrorCallBack, 'supplier-bill-management');
                break;
                // 提箱点核实
              case 'raise-node-verify':
              require.ensure([], function (require, exports, module) {
                var scope = require('raise-node-verify');
                loadScope(scope, '#raise-node-verify');
              }, loadErrorCallBack, 'raise-node-verify');
              break;
              // 核实提箱点详情
              case 'raise-node-verify-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('raise-node-verify-detail');
                  loadScope(scope, '#raise-node-verify-detail');
                }, loadErrorCallBack, 'raise-node-verify-detail');
                break;
                // 装卸点核实
              case 'load-unload-node-verify':
                require.ensure([], function (require, exports, module) {
                  var scope = require('load-unload-node-verify');
                  loadScope(scope, '#load-unload-node-verify');
                }, loadErrorCallBack, 'load-unload-node-verify');
                break;
                // 核实装卸点详情
                case 'load-unload-node-verify-detail':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('load-unload-node-verify-detail');
                    loadScope(scope, '#load-unload-node-verify-detail');
                  }, loadErrorCallBack, 'load-unload-node-verify-detail');
                  break;
                  // 还箱点核实
              case 'return-node-verify':
                require.ensure([], function (require, exports, module) {
                  var scope = require('return-node-verify');
                  loadScope(scope, '#return-node-verify');
                }, loadErrorCallBack, 'return-node-verify');
                break;
                // 核实还箱点详情
                case 'return-node-verify-detail':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('return-node-verify-detail');
                    loadScope(scope, '#return-node-verify-detail');
                  }, loadErrorCallBack, 'return-node-verify-detail');
                  break;
              // 暂停接单考评
              case 'suspend-accept-order-assess':
                require.ensure([], function (require, exports, module) {
                  var scope = require('suspend-accept-order-assess');
                  loadScope(scope, '#suspend-accept-order-assess');
                }, loadErrorCallBack, 'suspend-accept-order-assess');
                break;
              case 'supplier-bill-management-new':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-bill-management-new');
                  loadScope(scope, '#supplier-bill-management-new');
                }, loadErrorCallBack, 'supplier-bill-management-new');
                break;
                // 订单搜索（新业务接单）
              case 'order-search':
                require.ensure([], function (require, exports, module) {
                  var scope = require('order-search');
                  loadScope(scope, '#order-search');
                }, loadErrorCallBack, 'order-search');
                break;
              case 'port-entry-forecast':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('port-entry-forecast');
                    loadScope(scope, '#port-entry-forecast');
                  }, loadErrorCallBack, 'port-entry-forecast');
                  break;
                // 供应商账单制作
              case 'supplier-bill-make':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-bill-make');
                  loadScope(scope, '#supplier-bill-make');
                }, loadErrorCallBack, 'supplier-bill-make');
                break;
                // 供应商管理
              case 'supplier-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-management');
                  loadScope(scope, '#supplier-management');
                }, loadErrorCallBack, 'supplier-management');
                break;
                // 专组司机
            case 'special-group-driver':
                require.ensure([], function (require, exports, module) {
                  var scope = require('special-group-driver');
                  loadScope(scope, '#special-group-driver');
                }, loadErrorCallBack, 'special-group-driver');
                break;
                // 短驳调度
            case 'short-barge-dispatch':
                require.ensure([], function (require, exports, module) {
                  var scope = require('short-barge-dispatch');
                  loadScope(scope, '#short-barge-dispatch');
                }, loadErrorCallBack, 'short-barge-dispatch');
                break;
                     // 短驳待搭配
            case 'short-barge-match':
              require.ensure([], function (require, exports, module) {
                var scope = require('short-barge-match');
                loadScope(scope, '#short-barge-match');
              }, loadErrorCallBack, 'short-barge-match');
              break;
                // 短驳待预约
            case 'short-barge-dispatched':
                require.ensure([], function (require, exports, module) {
                  var scope = require('short-barge-dispatched');
                  loadScope(scope, '#short-barge-dispatched');
                }, loadErrorCallBack, 'short-barge-dispatched');
                break;
                    // 短驳已派车
            case 'short-barge-redispatched':
                require.ensure([], function (require, exports, module) {
                  var scope = require('short-barge-redispatched');
                  loadScope(scope, '#short-barge-redispatched');
                }, loadErrorCallBack, 'short-barge-redispatched');
                break;
                //司机账单管理
              case 'driver-bill-other-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-bill-other-management');
                  loadScope(scope, '#driver-bill-other-management');
                }, loadErrorCallBack, 'driver-bill-other-management');
                break;
                //车老板油费支付
              case 'boss-bill-pay-oil':
                require.ensure([], function (require, exports, module) {
                  var scope = require('boss-bill-pay-oil');
                  loadScope(scope, '#boss-bill-pay-oil');
                }, loadErrorCallBack, 'boss-bill-pay-oil');
                break;
                //车老板周现金支付
              case 'boss-bill-pay-week':
                require.ensure([], function (require, exports, module) {
                  var scope = require('boss-bill-pay-week');
                  loadScope(scope, '#boss-bill-pay-week');
                }, loadErrorCallBack, 'boss-bill-pay-week');
                break;
                //车老板提现申请
              case 'withdrawal-application':
                require.ensure([], function (require, exports, module) {
                  var scope = require('withdrawal-application');
                  loadScope(scope, '#withdrawal-application');
                }, loadErrorCallBack, 'withdrawal-application');
                break;
                //车老板月补贴
              case 'boss-bill-pay-mounth':
                require.ensure([], function (require, exports, module) {
                  var scope = require('boss-bill-pay-mounth');
                  loadScope(scope, '#boss-bill-pay-mounth');
                }, loadErrorCallBack, 'boss-bill-pay-mounth');
                break;
                //外发支付
              case 'other-bill-pay':
                require.ensure([], function (require, exports, module) {
                  var scope = require('other-bill-pay');
                  loadScope(scope, '#other-bill-pay');
                }, loadErrorCallBack, 'other-bill-pay');
                break;

                // 待确认做箱
              case 'seize-order-containerNotAccepted':
                require.ensure([], function (require, exports, module) {
                  var scope = require('seize-order-containerNotAccepted');
                  loadScope(scope, '#seize-order-containerNotAccepted');
                }, loadErrorCallBack, 'seize-order-containerNotAccepted');
                break;
                //待确认做箱
              case 'seize-order-boxNotAccepted':
                require.ensure([], function (require, exports, module) {
                  var scope = require('seize-order-boxNotAccepted');
                  loadScope(scope, '#seize-order-boxNotAccepted');
                }, loadErrorCallBack, 'seize-order-boxNotAccepted');
                break;
              // 开票品名维护
              case 'invoicing-product':
                require.ensure([], function (require, exports, module) {
                  var scope = require('invoicing-product');
                  loadScope(scope, '#invoicing-product');
                }, loadErrorCallBack, 'invoicing-product');
                break;
              // 应收账单盖章
              case 'stamping-bills-receivable':
                require.ensure([], function (require, exports, module) {
                  var scope = require('stamping-bills-receivable');
                  loadScope(scope, '#stamping-bills-receivable');
                }, loadErrorCallBack, 'stamping-bills-receivable');
                break;
                //待定价
              case 'wait-set-price':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-set-price');
                  loadScope(scope, '#wait-set-price');
                }, loadErrorCallBack, 'wait-set-price');
                break;
                //任务待搭配
                case 'wait-task-match':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('wait-task-match');
                    loadScope(scope, '#wait-task-match');
                  }, loadErrorCallBack, 'wait-task-match');
                  break;
                // 任务搭配待跟踪
                case 'collocation-task-tracking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('collocation-task-tracking');
                  loadScope(scope, '#collocation-task-tracking');
                }, loadErrorCallBack, 'collocation-task-tracking');
                break;
              //待定价(专线)
              case 'wait-set-price-special':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-set-price-special');
                  loadScope(scope, '#wait-set-price-special');
                }, loadErrorCallBack, 'wait-set-price-special');
                break;
                //补派业务
              case 'return-business':
                require.ensure([], function (require, exports, module) {
                  var scope = require('return-business');
                  loadScope(scope, '#return-business');
                }, loadErrorCallBack, 'return-business');
                break;
              //数据复核
              case 'pre-record-data-review':
              require.ensure([], function (require, exports, module) {
                var scope = require('pre-record-data-review');
                loadScope(scope, '#pre-record-data-review');
              }, loadErrorCallBack, 'pre-record-data-review');
              break;
              //预录管理
              case 'pre-record-manage':
              require.ensure([], function (require, exports, module) {
                var scope = require('pre-record-manage');
                loadScope(scope, '#pre-record-manage');
              }, loadErrorCallBack, 'pre-record-manage');
              break;
              //箱货数据补充/异常
              case 'container-prerecord-list':
              require.ensure([], function (require, exports, module) {
                var scope = require('container-prerecord-list');
                loadScope(scope, '#container-prerecord-list');
              }, loadErrorCallBack, 'container-prerecord-list');
              break;
                //做箱&放箱要求维护
              case 'container-requires-maintenance':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-requires-maintenance');
                  loadScope(scope, '#container-requires-maintenance');
                }, loadErrorCallBack, 'container-requires-maintenance');
                break;
                //待审核定价

              case 'wait-verify-price':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-verify-price');
                  loadScope(scope, '#wait-verify-price');
                }, loadErrorCallBack, 'wait-verify-price');
                break;
                //待调度

              case 'wait-dispatch':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-dispatch');
                  loadScope(scope, '#wait-dispatch');
                }, loadErrorCallBack, 'wait-dispatch');
                break;
                //待抢单

              case 'wait-grab-order':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-grab-order');
                  loadScope(scope, '#wait-grab-order');
                }, loadErrorCallBack, 'wait-grab-order');
                break;
                //待调度确认派单
              case 'container-confirm-order':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-confirm-order');
                  loadScope(scope, '#container-confirm-order');
                }, loadErrorCallBack, 'container-confirm-order');
                break;
                //待指定堆场
              case 'container-depot':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-depot');
                  loadScope(scope, '#container-depot');
                }, loadErrorCallBack, 'container-depot');
                break;
                //待指定寄单点
              case 'wait-appoint-mailing':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-appoint-mailing');
                  loadScope(scope, '#wait-appoint-mailing');
                }, loadErrorCallBack, 'wait-appoint-mailing');
                break;
                //已派车
              case 'container-dispatched':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-dispatched');
                  loadScope(scope, '#container-dispatched');
                }, loadErrorCallBack, 'container-dispatched');
                break;
                //应收应付审核
              case 'business-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('business-audit');
                  loadScope(scope, '#business-audit');
                }, loadErrorCallBack, 'business-audit');
                break;
              case 'door-cost-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('door-cost-management');
                  loadScope(scope, '#door-cost-management');
                }, loadErrorCallBack, 'door-cost-management');
                break;
                //箱利润报表
              case 'profit-report':
                require.ensure([], function (require, exports, module) {
                  var scope = require('profit-report');
                  loadScope(scope, '#profit-report');
                }, loadErrorCallBack, 'profit-report');
                break;
                //应付司机
              case 'driver-cost':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-cost');
                  loadScope(scope, '#driver-cost');
                }, loadErrorCallBack, 'driver-cost');
                break;
                //ETC办卡
              case 'etc-new-card':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-new-card');
                  loadScope(scope, '#etc-new-card');
                }, loadErrorCallBack, 'etc-new-card');
                break;
                //ETC充值
              case 'etc-charge-card':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-charge-card');
                  loadScope(scope, '#etc-charge-card');
                }, loadErrorCallBack, 'etc-charge-card');
                break;
                //ETC通行用户管理
              case 'etc-passservice-user':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-passservice-user');
                  loadScope(scope, '#etc-passservice-user');
                }, loadErrorCallBack, 'etc-passservice-user');
                break;
                //ETC通行管理
              case 'etc-passservice-package':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-passservice-package');
                  loadScope(scope, '#etc-passservice-package');
                }, loadErrorCallBack, 'etc-passservice-package');
                break;
                //ETC通行服务卡管理
              case 'etc-passservice-card':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-passservice-card');
                  loadScope(scope, '#etc-passservice-card');
                }, loadErrorCallBack, 'etc-passservice-card');
                break;
                //ETC通行发票列表
              case 'etc-passservice-invoice':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-passservice-invoice');
                  loadScope(scope, '#etc-passservice-invoice');
                }, loadErrorCallBack, 'etc-passservice-invoice');
                break;
                //ETC通行报销列表
              case 'etc-passservice-reimbursement-old':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-passservice-reimbursement-old');
                  loadScope(scope, '#etc-passservice-reimbursement-old');
                }, loadErrorCallBack, 'etc-passservice-reimbursement-old');
                break;
                //ETC报销
              case 'etc-passservice-reimbursement':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-passservice-reimbursement');
                  loadScope(scope, '#etc-passservice-reimbursement');
                }, loadErrorCallBack, 'etc-passservice-reimbursement');
                break;
                //ETC提现
              case 'etc-passservice-withdrawal':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-passservice-withdrawal');
                  loadScope(scope, '#etc-passservice-withdrawal');
                }, loadErrorCallBack, 'etc-passservice-withdrawal');
                break;
                //ETC费耘推送卡日志
              case 'etc-fy-log':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-fy-log');
                  loadScope(scope, '#etc-fy-log');
                }, loadErrorCallBack, 'etc-fy-log');
                break;
                //还款管理
              case 'repay-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('repay-management');
                  loadScope(scope, '#repay-management');
                }, loadErrorCallBack, 'repay-management');
                break;
                //建行贷款还款计划
              case 'repayment-plan-ccb':
                require.ensure([], function (require, exports, module) {
                  var scope = require('repayment-plan-ccb');
                  loadScope(scope, '#repayment-plan-ccb');
                }, loadErrorCallBack, 'repayment-plan-ccb');
                break;
                //建行贷款银行卡激活
              case 'activation-card-ccb':
                require.ensure([], function (require, exports, module) {
                  var scope = require('activation-card-ccb');
                  loadScope(scope, '#activation-card-ccb');
                }, loadErrorCallBack, 'activation-card-ccb');
                break;
                //应付费用维护
              case 'driver-cost-maintenance':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-cost-maintenance');
                  loadScope(scope, '#driver-cost-maintenance');
                }, loadErrorCallBack, 'driver-cost-maintenance');
                break;
                //上报费用审核
              case 'reporting-cost-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('reporting-cost-audit');
                  loadScope(scope, '#reporting-cost-audit');
                }, loadErrorCallBack, 'reporting-cost-audit');
                break;
                //提单动态查询
              case 'status-tracking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('status-tracking');
                  loadScope(scope, '#status-tracking');
                }, loadErrorCallBack, 'status-tracking');
                break;
                //司机认证
              case 'driverer-certification':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driverer-certification');
                  loadScope(scope, '#driverer-certification');
                }, loadErrorCallBack, 'driverer-certification');
                break;
                //VIP 司机管理
              case 'driver-vip-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-vip-management');
                  loadScope(scope, '#driver-vip-management');
                }, loadErrorCallBack, 'driver-vip-management');
                break;
              case 'maintain-truck-info':
                require.ensure([], function (require, exports, module) {
                  var scope = require('maintain-truck-info');
                  loadScope(scope, '#maintain-truck-info');
                }, loadErrorCallBack, 'maintain-truck-info');
                break;
            case 'wait-grab-order':
                require.ensure([], function (require, exports, module) {
                    var scope = require('wait-grab-order');
                    loadScope(scope, '#wait-grab-order');
                }, loadErrorCallBack,'wait-grab-order');
                break;
            //待指定堆场
            case 'container-depot':
                require.ensure([], function (require, exports, module) {
                    var scope = require('container-depot');
                    loadScope(scope, '#container-depot');
                }, loadErrorCallBack,'container-depot');
                break;
            //待指定寄单点
            case 'wait-appoint-mailing':
                require.ensure([], function (require, exports, module) {
                    var scope = require('wait-appoint-mailing');
                    loadScope(scope, '#wait-appoint-mailing');
                }, loadErrorCallBack,'wait-appoint-mailing');
                break;
            //已派车
            case 'container-dispatched':
                require.ensure([], function (require, exports, module) {
                    var scope = require('container-dispatched');
                    loadScope(scope, '#container-dispatched');
                }, loadErrorCallBack,'container-dispatched');
                break;
            //应收应付审核
            case 'business-audit':
                require.ensure([], function (require, exports, module) {
                    var scope = require('business-audit');
                    loadScope(scope, '#business-audit');
                }, loadErrorCallBack,'business-audit');
                break;
            case 'door-cost-management':
                require.ensure([], function (require, exports, module) {
                    var scope = require('door-cost-management');
                    loadScope(scope, '#door-cost-management');
                }, loadErrorCallBack,'door-cost-management');
                break;
            //箱利润报表
            case 'profit-report':
                require.ensure([], function (require, exports, module) {
                    var scope = require('profit-report');
                    loadScope(scope, '#profit-report');
                }, loadErrorCallBack,'profit-report');
                break;
            //应付司机
            case 'driver-cost':
                require.ensure([], function (require, exports, module) {
                    var scope = require('driver-cost');
                    loadScope(scope, '#driver-cost');
                }, loadErrorCallBack,'driver-cost');
                break;
            //ETC办卡
            case 'etc-new-card':
            require.ensure([], function (require, exports, module) {
                var scope = require('etc-new-card');
                loadScope(scope, '#etc-new-card');
            }, loadErrorCallBack,'etc-new-card');
            break;
            //ETC充值
            case 'etc-charge-card':
            require.ensure([], function (require, exports, module) {
                var scope = require('etc-charge-card');
                loadScope(scope, '#etc-charge-card');
            }, loadErrorCallBack,'etc-charge-card');
            break;
            //还款计划
            case 'repay-management':
            require.ensure([], function (require, exports, module) {
                var scope = require('repay-management');
                loadScope(scope, '#repay-management');
            }, loadErrorCallBack,'repay-management');
            break;
            //建行贷款还款计划
            case 'repayment-plan-ccb':
            require.ensure([], function (require, exports, module) {
                var scope = require('repayment-plan-ccb');
                loadScope(scope, '#repayment-plan-ccb');
            }, loadErrorCallBack,'repayment-plan-ccb');
            break;
            //建行贷款银行卡激活
            case 'activation-card-ccb':
            require.ensure([], function (require, exports, module) {
                var scope = require('activation-card-ccb');
                loadScope(scope, '#activation-card-ccb');
            }, loadErrorCallBack,'activation-card-ccb');
            break;
            //应付费用维护
            case 'driver-cost-maintenance':
                require.ensure([], function (require, exports, module) {
                    var scope = require('driver-cost-maintenance');
                    loadScope(scope, '#driver-cost-maintenance');
                }, loadErrorCallBack,'driver-cost-maintenance');
                break;
            //上报费用审核
            case 'reporting-cost-audit':
                require.ensure([], function (require, exports, module) {
                    var scope = require('reporting-cost-audit');
                    loadScope(scope, '#reporting-cost-audit');
                }, loadErrorCallBack,'reporting-cost-audit');
                break;
            //提单动态查询
            case 'status-tracking':
                require.ensure([], function (require, exports, module) {
                    var scope = require('status-tracking');
                    loadScope(scope, '#status-tracking');
                }, loadErrorCallBack,'status-tracking');
                break;
            //司机认证
            case 'driverer-certification':
                require.ensure([], function (require, exports, module) {
                    var scope = require('driverer-certification');
                    loadScope(scope, '#driverer-certification');
                }, loadErrorCallBack,'driverer-certification');
                break;
            //VIP 司机管理
            case 'driver-vip-management':
                require.ensure([], function (require, exports, module) {
                    var scope = require('driver-vip-management');
                    loadScope(scope, '#driver-vip-management');
                }, loadErrorCallBack,'driver-vip-management');
                break;
            case 'maintain-truck-info':
                require.ensure([], function (require, exports, module) {
                    var scope = require('maintain-truck-info');
                    loadScope(scope, '#maintain-truck-info');
                }, loadErrorCallBack,'maintain-truck-info');
                break;

            // 活动详情
                case 'act-detail':
                require.ensure([], function (require, exports, module) {
                    var scope = require('act-detail');
                    loadScope(scope, '#act-detail');
                }, loadErrorCallBack,'act-detail');
                break;
            // 展会管理
                case 'exhibition-information':
                require.ensure([], function (require, exports, module) {
                    var scope = require('exhibition-information');
                    loadScope(scope, '#exhibition-information');
                }, loadErrorCallBack,'exhibition-information');
                break;
            // 客户起诉
                case 'customer-prosecution':
                require.ensure([], function (require, exports, module) {
                    var scope = require('customer-prosecution');
                    loadScope(scope, '#customer-prosecution');
                }, loadErrorCallBack,'customer-prosecution');
                break;
                // 查价记录
                case 'price-check-record':
                require.ensure([], function (require, exports, module) {
                    var scope = require('price-check-record');
                    loadScope(scope, '#price-check-record');
                }, loadErrorCallBack,'price-check-record');
                break;
                // 门点区域查询
                case 'query-area':
                require.ensure([], function (require, exports, module) {
                    var scope = require('query-area');
                    loadScope(scope, '#query-area');
                }, loadErrorCallBack,'query-area');
                break;
                // 客户附加费用查询
                case 'query-extra-money':
                require.ensure([], function (require, exports, module) {
                    var scope = require('query-extra-money');
                    loadScope(scope, '#query-extra-money');
                }, loadErrorCallBack,'query-extra-money');
                break;
                // 客户登记
                case 'customer-registration':
                require.ensure([], function (require, exports, module) {
                    var scope = require('customer-registration');
                    loadScope(scope, '#customer-registration');
                }, loadErrorCallBack,'customer-registration');
                break;
                // 新版标准报价查询
                case 'standard-quotation-inquiry':
                require.ensure([], function (require, exports, module) {
                    var scope = require('standard-quotation-inquiry');
                    loadScope(scope, '#standard-quotation-inquiry');
                }, loadErrorCallBack,'standard-quotation-inquiry');
                break;
            // 客户查询
                case 'query-customer':
                require.ensure([], function (require, exports, module) {
                    var scope = require('query-customer');
                    loadScope(scope, '#query-customer');
                }, loadErrorCallBack,'query-customer');
                break;


                //车辆实时位置
              case 'car-position':
                require.ensure([], function (require, exports, module) {
                  var scope = require('car-position');
                  loadScope(scope, '#car-position');
                }, loadErrorCallBack, 'car-position');
                break;

                //订单详情(新)
              case 'details-new':
                require.ensure([], function (require, exports, module) {
                  var scope = require('details-new');
                  loadScope(scope, '#details-new');
                }, loadErrorCallBack, 'details-new');
                break;


                //车辆轨迹
              case 'vehicle-trajectory':
                require.ensure([], function (require, exports, module) {
                  var scope = require('vehicle-trajectory');
                  loadScope(scope, '#vehicle-trajectory');
                }, loadErrorCallBack, 'vehicle-trajectory');
                break;
                //销售指派任务管理
              case 'appionted-task':
                require.ensure([], function (require, exports, module) {
                  var scope = require('appionted-task');
                  loadScope(scope, '#appionted-task');
                }, loadErrorCallBack, 'appionted-task');
                break;
                // 检验监装管理
              case 'supervision-management':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('supervision-management');
                    loadScope(scope, '#supervision-management');
                  }, loadErrorCallBack, 'supervision-management');
                  break;
                //新客户账户
              case 'new-customer-account':
                require.ensure([], function (require, exports, module) {
                  var scope = require('new-customer-account');
                  loadScope(scope, '#new-customer-account');
                }, loadErrorCallBack, 'new-customer-account');
                break;
                //客户档案
              case 'customer-files':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-files');
                  loadScope(scope, '#customer-files');
                }, loadErrorCallBack, 'customer-files');
                break;
                //我的客户
              case 'my-customer-files':
                require.ensure([], function (require, exports, module) {
                  var scope = require('my-customer-files');
                  loadScope(scope, '#my-customer-files');
                }, loadErrorCallBack, 'my-customer-files');
                break;
                //相同实控人客户
              case 'custmoer-real-control':
                require.ensure([], function (require, exports, module) {
                  var scope = require('custmoer-real-control');
                  loadScope(scope, '#custmoer-real-control');
                }, loadErrorCallBack, 'custmoer-real-control');
                break;
                //新增or编辑相同实控人客户
              case 'custmoer-real-control-add-edit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('custmoer-real-control-add-edit');
                  loadScope(scope, '#custmoer-real-control-add-edit');
                }, loadErrorCallBack, 'custmoer-real-control-add-edit');
                break;
                //发票抬头审核
              case 'invoice-review':
                require.ensure([], function (require, exports, module) {
                  var scope = require('invoice-review');
                  loadScope(scope, '#invoice-review');
                }, loadErrorCallBack, 'invoice-review');
                break;
                //发票抬头审核 商务
              case 'invoice-review-bd':
                require.ensure([], function (require, exports, module) {
                  var scope = require('invoice-review-bd');
                  loadScope(scope, '#invoice-review-bd');
                }, loadErrorCallBack, 'invoice-review-bd');
                break;
                //应收账单管理2
              case 'receivable-bill-management-sec':
                require.ensure([], function (require, exports, module) {
                  var scope = require('receivable-bill-management-sec');
                  loadScope(scope, '#receivable-bill-management-sec');
                }, loadErrorCallBack, 'receivable-bill-management-sec');
                break;
                // 开票申请
              case 'invoicing-application':
                require.ensure([], function (require, exports, module) {
                  var scope = require('invoicing-application');
                  loadScope(scope, '#invoicing-application');
                }, loadErrorCallBack, 'invoicing-application');
                break;
                //应收账单详情
              case 'receivable-bill-details':
                require.ensure([], function (require, exports, module) {
                  var scope = require('receivable-bill-details');
                  loadScope(scope, '#receivable-bill-details');
                }, loadErrorCallBack, 'receivable-bill-details');
                break;


                //应收费用2
              case 'new-cost-entry':
                require.ensure([], function (require, exports, module) {
                  var scope = require('new-cost-entry');
                  loadScope(scope, '#new-cost-entry');
                }, loadErrorCallBack, 'new-cost-entry');
                break;
                //业务接单
              case 'box-list':
                require.ensure([], function (require, exports, module) {
                  var scope = require('box-list');
                  loadScope(scope, '#box-list');
                }, loadErrorCallBack, 'box-list');
                break;
                //业务接单
              case 'new-box-list':
                require.ensure([], function (require, exports, module) {
                  var scope = require('new-box-list');
                  loadScope(scope, '#new-box-list');
                }, loadErrorCallBack, 'new-box-list');
                break;

                //驳箱下单
              case 'short-barge':
                require.ensure([], function (require, exports, module) {
                  var scope = require('short-barge');
                  loadScope(scope, '#short-barge');
                }, loadErrorCallBack, 'short-barge');
                break;

                //客户档案管理
              case 'customer-manage':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-manage');
                  loadScope(scope, '#customer-manage');
                }, loadErrorCallBack, 'customer-manage');
                break;
                // 客户业务评价
              case 'customer-business-evaluation':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-business-evaluation');
                  loadScope(scope, '#customer-business-evaluation');
                }, loadErrorCallBack, 'customer-business-evaluation');
                break;
                //司机账单详情
              case 'driver-bill-details':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-bill-details');
                  loadScope(scope, '#driver-bill-details');
                }, loadErrorCallBack, 'driver-bill-details');
                break;
                //供应商账单详情
              case 'supplier-bill-details':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-bill-details');
                  loadScope(scope, '#supplier-bill-details');
                }, loadErrorCallBack, 'supplier-bill-details');
                break;
                //外发账单详情
              case 'driver-bill-other-details':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-bill-other-details');
                  loadScope(scope, '#driver-bill-other-details');
                }, loadErrorCallBack, 'driver-bill-other-details');
                break;
                //司机账单制作
              case 'driver-bill-make':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-bill-make');
                  loadScope(scope, '#driver-bill-make');
                }, loadErrorCallBack, 'driver-bill-make');
                break;
                //编辑订单
              case 'edit-container':
                require.ensure([], function (require, exports, module) {
                  var scope = require('edit-container');
                  loadScope(scope, '#edit-container');
                }, loadErrorCallBack, 'edit-container');
                break;
                //公司成本
                // case 'company-cost':
                //     require.ensure([], function (require, exports, module) {
                //         var scope = require('company-cost');
                //         loadScope(scope, '#company-cost');
                //     }, loadErrorCallBack,'company-cost');
                //     break;

                //公司成本
              case 'company-cost-new':
                require.ensure([], function (require, exports, module) {
                  var scope = require('company-cost-new');
                  loadScope(scope, '#company-cost-new');
                }, loadErrorCallBack, 'company-cost-new');
                break;
              // 供应商申请
              case 'supplier-application':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-application');
                  loadScope(scope, '#supplier-application');
                }, loadErrorCallBack, 'supplier-application');
                break;
                // 供应商档案
              case 'supplier-file':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-file');
                  loadScope(scope, '#supplier-file');
                }, loadErrorCallBack, 'supplier-file');
                break;
               // 供应商档案-详情
                case 'supplier-file-edit':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('supplier-file-edit');
                    loadScope(scope, '#supplier-file-edit');
                  }, loadErrorCallBack, 'supplier-file-edit');
                break;
              // 供应商账单制作
                case 'supplier-make-bill':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('supplier-make-bill');
                    loadScope(scope, '#supplier-make-bill');
                  }, loadErrorCallBack, 'supplier-make-bill');
                break;
              // 供应商企业申请
              case 'supplier-application-company':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-application-company');
                  loadScope(scope, '#supplier-application-company');
                }, loadErrorCallBack, 'supplier-application-company');
                break;
              // 供应商个人申请
              case 'supplier-application-personal':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-application-personal');
                  loadScope(scope, '#supplier-application-personal');
                }, loadErrorCallBack, 'supplier-application-personal');
                break;
              // 供应商申请编辑
              case 'supplier-application-edit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-application-edit');
                  loadScope(scope, '#supplier-application-edit');
                }, loadErrorCallBack, 'supplier-application-edit');
                break;
                //箱子列表
              case 'set-box-list':
                require.ensure([], function (require, exports, module) {
                  var scope = require('set-box-list');
                  loadScope(scope, '#set-box-list');
                }, loadErrorCallBack, 'set-box-list');
                break;
                //应收账单制作
              case 'making-account-receivable':
                require.ensure([], function (require, exports, module) {
                  var scope = require('making-account-receivable');
                  loadScope(scope, '#making-account-receivable');
                }, loadErrorCallBack, 'making-account-receivable');
                break;
                //暂停业务
                case 'suspend-business':
                    require.ensure([], function (require, exports, module) {
                    var scope = require('suspend-business');
                    loadScope(scope, '#suspend-business');
                    }, loadErrorCallBack, 'suspend-business');
                    break;
                //短信运营
              case 'sms-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('sms-management');
                  loadScope(scope, '#sms-management');
                }, loadErrorCallBack, 'sms-management');
                break;
                //箱封号复核
              case 'container-seal-entry':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-seal-entry');
                  loadScope(scope, '#container-seal-entry');
                }, loadErrorCallBack, 'container-seal-entry');
                break;
                //报关管理
              case 'yangshan-declare-manage':
                require.ensure([], function (require, exports, module) {
                  var scope = require('yangshan-declare-manage');
                  loadScope(scope, '#yangshan-declare-manage');
                }, loadErrorCallBack, 'yangshan-declare-manage');
                break;
              // 委托报关
              case 'entrusted-customs-declaration':
                require.ensure([], function (require, exports, module) {
                  var scope = require('entrusted-customs-declaration');
                  loadScope(scope, '#entrusted-customs-declaration');
                }, loadErrorCallBack, 'entrusted-customs-declaration');
                break;
                //报关统计
              case 'declare-statistics':
                require.ensure([], function (require, exports, module) {
                  var scope = require('declare-statistics');
                  loadScope(scope, '#declare-statistics');
                }, loadErrorCallBack, 'declare-statistics');
                break;
              case 'car-trace-report':
                require.ensure([], function (require, exports, module) {
                  var scope = require('car-trace-report');
                  loadScope(scope, '#car-trace-report');
                }, loadErrorCallBack, 'car-trace-report');
                break;
              case 'car-trace-report-new':
                require.ensure([], function (require, exports, module) {
                  var scope = require('car-trace-report-new');
                  loadScope(scope, '#car-trace-report-new');
                }, loadErrorCallBack, 'car-trace-report-new');
                break;
              //车辆跟踪统计表详情
              case 'car-trace-report-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('car-trace-report-detail');
                  loadScope(scope, '#car-trace-report-detail');
                }, loadErrorCallBack, 'car-trace-report-detail');
              break;
                //做箱情况
              case 'container-situation-report':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-situation-report');
                  loadScope(scope, '#container-situation-report');
                }, loadErrorCallBack, 'container-situation-report');
                break;
                //开票申请
              case 'receivable-issue-invoice':
                require.ensure([], function (require, exports, module) {
                  var scope = require('receivable-issue-invoice');
                  loadScope(scope, '#receivable-issue-invoice');
                }, loadErrorCallBack, 'receivable-issue-invoice');
                break;
                // 门点管理
              case 'door-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('door-management');
                  loadScope(scope, '#door-management');
                }, loadErrorCallBack, 'door-management');
                break;
                // 门点地址审核
              case 'door-point-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('door-point-audit');
                  loadScope(scope, '#door-point-audit');
                }, loadErrorCallBack, 'door-point-audit');
                break;
              // 码头地址审核
              case 'quay-point-audit':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('quay-point-audit');
                    loadScope(scope, '#quay-point-audit');
                  }, loadErrorCallBack, 'quay-point-audit');
                break;
                // 堆场地址审核
              case 'yard-point-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('yard-point-audit');
                  loadScope(scope, '#yard-point-audit');
                }, loadErrorCallBack, 'yard-point-audit');
                break;
                // 角色管理
              case 'role-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('role-management');
                  loadScope(scope, '#role-management');
                }, loadErrorCallBack, 'role-management');
                break;
                // 密码修改
              case 'password-modify':
                require.ensure([], function (require, exports, module) {
                  var scope = require('password-modify');
                  loadScope(scope, '#password-modify');
                }, loadErrorCallBack, 'password-modify');
                break;
                // 权限管理
              case 'auth-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('auth-management');
                  loadScope(scope, '#auth-management');
                }, loadErrorCallBack, 'auth-management');
                break;
                // 费用项设置
              case 'cost-setting':
                require.ensure([], function (require, exports, module) {
                  var scope = require('cost-setting');
                  loadScope(scope, '#cost-setting');
                }, loadErrorCallBack, 'cost-setting');
                break;
                // 进港数据对比
              case 'data-compare':
                require.ensure([], function (require, exports, module) {
                  var scope = require('data-compare');
                  loadScope(scope, '#data-compare');
                }, loadErrorCallBack, 'data-compare');
                break;
                //录单中心
              case 'recording-center':
                require.ensure([], function (require, exports, module) {
                  var scope = require('recording-center');
                  loadScope(scope, '#recording-center');
                }, loadErrorCallBack, 'recording-center');
                break;
              case 'invoice-write-off':
                require.ensure([], function (require, exports, module) {
                  var scope = require('invoice-write-off');
                  loadScope(scope, '#invoice-write-off');
                }, loadErrorCallBack, 'invoice-write-off');
                break;

              //收款单管理
              case 'receipt-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('receipt-management');
                  loadScope(scope, '#receipt-management');
                }, loadErrorCallBack, 'receipt-management');
                break;

                // 付款计划
              case 'collection-plan':
                require.ensure([], function (require, exports, module) {
                  var scope = require('collection-plan');
                  loadScope(scope, '#collection-plan');
                }, loadErrorCallBack, 'collection-plan');
                break;
                // 系统账户管理
              case 'accounts-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('accounts-management');
                  loadScope(scope, '#accounts-management');
                }, loadErrorCallBack, 'accounts-management');
                break;

                // 空箱业务
              case "empty-container-business":
                require.ensure([], function (require, exports, module) {
                  var scope = require("empty-container-business")
                  loadScope(scope, "#empty-container-business")
                }, loadErrorCallBack, "empty-container-business");
                break;
                //客户咨询
              case 'customer-consultation':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-consultation');
                  loadScope(scope, '#customer-consultation');
                }, loadErrorCallBack, 'customer-consultation');
                break;
                //合同申请
              case 'contract-apply-old':
                require.ensure([], function (require, exports, module) {
                  var scope = require('contract-apply-old');
                  loadScope(scope, '#contract-apply-old');
                }, loadErrorCallBack, 'contract-apply-old');
                break;
                   //合同申请新
              case 'contract-apply':
                require.ensure([], function (require, exports, module) {
                  var scope = require('contract-apply');
                  loadScope(scope, '#contract-apply');
                }, loadErrorCallBack, 'contract-apply');
                break;
              //建档申请
                case 'filing-application':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('filing-application');
                    loadScope(scope, '#filing-application');
                  }, loadErrorCallBack, 'filing-application');
                  break;
                //合同审核
              case 'contract-review':
                require.ensure([], function (require, exports, module) {
                  var scope = require('contract-review');
                  loadScope(scope, '#contract-review');
                }, loadErrorCallBack, 'contract-review');
                break;
                //注册审核
              case 'register-review':
                require.ensure([], function (require, exports, module) {
                  var scope = require('register-review');
                  loadScope(scope, '#register-review');
                }, loadErrorCallBack, 'register-review');
                break;
                //客户注册记录
              case 'certification-review':
                require.ensure([], function (require, exports, module) {
                  var scope = require('certification-review');
                  loadScope(scope, '#certification-review');
                }, loadErrorCallBack, 'certification-review');
                break;
                //加入企业申请
              case 'join-request':
                require.ensure([], function (require, exports, module) {
                  var scope = require('join-request');
                  loadScope(scope, '#join-request');
                }, loadErrorCallBack, 'join-request');
                break;
                //退出企业申请
              case 'quit-request':
                require.ensure([], function (require, exports, module) {
                  var scope = require('quit-request');
                  loadScope(scope, '#quit-request');
                }, loadErrorCallBack, 'quit-request');
                break;
                //上传托书
              case 'upload-booking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('upload-booking');
                  loadScope(scope, '#upload-booking');
                }, loadErrorCallBack, 'upload-booking');
                break;
                // 按票导出账单
              case 'export-ticket-bill':
                require.ensure([], function (require, exports, module) {
                  var scope = require('export-ticket-bill');
                  loadScope(scope, '#export-ticket-bill');
                }, loadErrorCallBack, 'export-ticket-bill');
                break;
                //跟踪日志管理
              case 'trace-log-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('trace-log-management');
                  loadScope(scope, '#trace-log-management');
                }, loadErrorCallBack, 'trace-log-management');
                break;
                //堆场
              case 'container-yard':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-yard');
                  loadScope(scope, '#container-yard');
                }, loadErrorCallBack, 'container-yard');
                break;
                //指定落箱堆场
              case 'drop-box-yard':
                require.ensure([], function (require, exports, module) {
                  var scope = require('drop-box-yard');
                  loadScope(scope, '#drop-box-yard');
                }, loadErrorCallBack, 'drop-box-yard');
                break;
                //二次进港跟踪
              case 'secondary-arrival-tracking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('secondary-arrival-tracking');
                  loadScope(scope, '#secondary-arrival-tracking');
                }, loadErrorCallBack, 'secondary-arrival-tracking');
                break;
                //司机违法违章
              case 'driver-regulations-handle':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-regulations-handle');
                  loadScope(scope, '#driver-regulations-handle');
                }, loadErrorCallBack, 'driver-regulations-handle');
                break;
                //车贴审核
              case 'car-sticker-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('car-sticker-management');
                  loadScope(scope, '#car-sticker-management');
                }, loadErrorCallBack, 'car-sticker-management');
                break;
                //请假考核
              case 'leave-assessmentl':
                require.ensure([], function (require, exports, module) {
                  var scope = require('leave-assessmentl');
                  loadScope(scope, '#leave-assessmentl');
                }, loadErrorCallBack, 'leave-assessmentl');
                break;
                //线下安全培训
              case 'driver-offline-training':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-offline-training');
                  loadScope(scope, '#driver-offline-training');
                }, loadErrorCallBack, 'driver-offline-training');
                break;
                 //线上安全培训
              case 'training-plan':
                require.ensure([], function (require, exports, module) {
                  var scope = require('training-plan');
                  loadScope(scope, '#training-plan');
                }, loadErrorCallBack, 'training-plan');
                break;
                //司机承诺书
              case 'driver-safety-commitment':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-safety-commitment');
                  loadScope(scope, '#driver-safety-commitment');
                }, loadErrorCallBack, 'driver-safety-commitment');
                break;
                //费用审核失败
              case 'expenses-audit-failure':
                require.ensure([], function (require, exports, module) {
                  var scope = require('expenses-audit-failure');
                  loadScope(scope, '#expenses-audit-failure');
                }, loadErrorCallBack, 'expenses-audit-failure');
                break;
                //逾期账款
              case 'overdue-records':
                require.ensure([], function (require, exports, module) {
                  var scope = require('overdue-records');
                  loadScope(scope, '#overdue-records');
                }, loadErrorCallBack, 'overdue-records');
                break;
                //电话推广
              case 'phone-bar':
                require.ensure([], function (require, exports, module) {
                  var scope = require('phone-bar');
                  loadScope(scope, '#phone-bar');
                }, loadErrorCallBack, 'phone-bar');
                break;
                //司机请假
              case 'ask-leave':
                require.ensure([], function (require, exports, module) {
                  var scope = require('ask-leave');
                  loadScope(scope, '#ask-leave');
                }, loadErrorCallBack, 'ask-leave');
                break;
                //司机投诉
              case 'driver-complaints':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-complaints');
                  loadScope(scope, '#driver-complaints');
                }, loadErrorCallBack, 'driver-complaints');
                break;
                //仓库
              case 'container-storehouse':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-storehouse');
                  loadScope(scope, '#container-storehouse');
                }, loadErrorCallBack, 'container-storehouse');
                break;
                //寄单点
              case 'send-bill-pot':
                require.ensure([], function (require, exports, module) {
                  var scope = require('send-bill-pot');
                  loadScope(scope, '#send-bill-pot');
                }, loadErrorCallBack, 'send-bill-pot');
                break;
                // 加封地维护
              case 'sealed-maintenance':
                require.ensure([], function (require, exports, module) {
                  var scope = require('sealed-maintenance');
                  loadScope(scope, '#sealed-maintenance');
                }, loadErrorCallBack, 'sealed-maintenance');
                break;
                //门点区域
              case 'door-area':
                require.ensure([], function (require, exports, module) {
                  var scope = require('door-area');
                  loadScope(scope, '#door-area');
                }, loadErrorCallBack, 'door-area');
                break;
                //船公司管理
              case 'shipping-company-manage':
                require.ensure([], function (require, exports, module) {
                  var scope = require('shipping-company-manage');
                  loadScope(scope, '#shipping-company-manage');
                }, loadErrorCallBack, 'shipping-company-manage');
                break;
                //客户标签设置
              case 'customer-tip':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-tip');
                  loadScope(scope, '#customer-tip');
                }, loadErrorCallBack, 'customer-tip');
                break;
                //待确认打单
              case 'confirm-packing':
                require.ensure([], function (require, exports, module) {
                  var scope = require('confirm-packing');
                  loadScope(scope, '#confirm-packing');
                }, loadErrorCallBack, 'confirm-packing');
                break;
                //驾驶员邀请情况
              case 'driver-invitation':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-invitation');
                  loadScope(scope, '#driver-invitation');
                }, loadErrorCallBack, 'driver-invitation');
                break;
                //VIP抢单控制
              case 'control-vip-grab':
                require.ensure([], function (require, exports, module) {
                  var scope = require('control-vip-grab');
                  loadScope(scope, '#control-vip-grab');
                }, loadErrorCallBack, 'control-vip-grab');
                break;
                //
              case 'control-vip-grab':
                require.ensure([], function (require, exports, module) {
                  var scope = require('control-vip-grab');
                  loadScope(scope, '#control-vip-grab');
                }, loadErrorCallBack, 'control-vip-grab');
                break;
                //箱封号录入
              case 'container-seal-input':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-seal-input');
                  loadScope(scope, '#container-seal-input');
                }, loadErrorCallBack, 'container-seal-input');
                break;
                //发单人审核
              case 'feidansender-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('feidansender-audit');
                  loadScope(scope, '#feidansender-audit');
                }, loadErrorCallBack, 'feidansender-audit');
                break;
                //旺季超额审批
              case 'busy-season-approval':
                require.ensure([], function (require, exports, module) {
                  var scope = require('busy-season-approval');
                  loadScope(scope, '#busy-season-approval');
                }, loadErrorCallBack, 'busy-season-approval');
                break;
                //旺季超额审批详情
              case 'busy-season-approval-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('busy-season-approval-detail');
                  loadScope(scope, '#busy-season-approval-detail');
                }, loadErrorCallBack, 'busy-season-approval-detail');
                break;
                //海运订舱待报价
              case 'ocean-freight-booking-inquiry':
                require.ensure([], function (require, exports, module) {
                  var scope = require('ocean-freight-booking-inquiry');
                  loadScope(scope, '#ocean-freight-booking-inquiry');
                }, loadErrorCallBack, 'ocean-freight-booking-inquiry');
                break;
                //海运订舱待确认舱位
              case 'sea-freight-booking-confirm':
                require.ensure([], function (require, exports, module) {
                  var scope = require('sea-freight-booking-confirm');
                  loadScope(scope, '#sea-freight-booking-confirm');
                }, loadErrorCallBack, 'sea-freight-booking-confirm');
                break;
                //海运订舱询价
              case 'sea-freight-booking-inquiry-list':
                require.ensure([], function (require, exports, module) {
                  var scope = require('sea-freight-booking-inquiry-list');
                  loadScope(scope, '#sea-freight-booking-inquiry-list');
                }, loadErrorCallBack, 'sea-freight-booking-inquiry-list');
                break;
                //落箱进港费维护
              case 'container-price-maintain':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-price-maintain');
                  loadScope(scope, '#container-price-maintain');
                }, loadErrorCallBack, 'container-price-maintain');
                break;
                //待安排进港
              case 'wait-arrange-harbour':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-arrange-harbour');
                  loadScope(scope, '#wait-arrange-harbour');
                }, loadErrorCallBack, 'wait-arrange-harbour');
                break;
                //待定价
              case 'drop-container-set-price':
                require.ensure([], function (require, exports, module) {
                  var scope = require('drop-container-set-price');
                  loadScope(scope, '#drop-container-set-price');
                }, loadErrorCallBack, 'drop-container-set-price');
                break;
                //待审核定价
              case 'drop-container-verify-price':
                require.ensure([], function (require, exports, module) {
                  var scope = require('drop-container-verify-price');
                  loadScope(scope, '#drop-container-verify-price');
                }, loadErrorCallBack, 'drop-container-verify-price');
                break;
                //待调度
              case 'drop-container-dispatch':
                require.ensure([], function (require, exports, module) {
                  var scope = require('drop-container-dispatch');
                  loadScope(scope, '#drop-container-dispatch');
                }, loadErrorCallBack, 'drop-container-dispatch');
                break;
                //司机账户
              case 'capacity-account':
                require.ensure([], function (require, exports, module) {
                  var scope = require('capacity-account');
                  loadScope(scope, '#capacity-account');
                }, loadErrorCallBack, 'capacity-account');
                break;
                //司机授信额度
              case 'capacity-credit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('capacity-credit');
                  loadScope(scope, '#capacity-credit');
                }, loadErrorCallBack, 'capacity-credit');
                break;
              //司机账户详情
              case 'capacity-amount-flow':
                require.ensure([], function (require, exports, module) {
                  var scope = require('capacity-amount-flow');
                  loadScope(scope, '#capacity-amount-flow');
                }, loadErrorCallBack, 'capacity-amount-flow');
                break;
              //上传海运订舱托书
              case 'upload-shipping-booking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('upload-shipping-booking');
                  loadScope(scope, '#upload-shipping-booking');
                }, loadErrorCallBack, 'upload-shipping-booking');
                break;
              //海运订舱托书录入
              case 'entry-shipping-booking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('entry-shipping-booking');
                  loadScope(scope, '#entry-shipping-booking');
                }, loadErrorCallBack, 'entry-shipping-booking');
                break;
                 //海运订舱
              case 'shipping-booking-space':
                require.ensure([], function (require, exports, module) {
                  var scope = require('shipping-booking-space');
                  loadScope(scope, '#shipping-booking-space');
                }, loadErrorCallBack, 'shipping-booking-space');
                break;
              // 提单管理
              case 'shipping-bill-lading':
                require.ensure([], function (require, exports, module) {
                  var scope = require('shipping-bill-lading');
                  loadScope(scope, '#shipping-bill-lading');
                }, loadErrorCallBack, 'shipping-bill-lading');
                break;
              // 提单-待发客户
              case 'shipping-bill-lading-dispatch-customer':
                require.ensure([], function (require, exports, module) {
                  var scope = require('shipping-bill-lading-dispatch-customer');
                  loadScope(scope, '#shipping-bill-lading-dispatch-customer');
                }, loadErrorCallBack, 'shipping-bill-lading-dispatch-customer');
                break;
              // 提单-待客户确认
              case 'shipping-bill-lading-send':
                require.ensure([], function (require, exports, module) {
                  var scope = require('shipping-bill-lading-send');
                  loadScope(scope, '#shipping-bill-lading-send');
                }, loadErrorCallBack, 'shipping-bill-lading-send');
                break;
              // 提单-待截单
              case 'shipping-bill-lading-confirmed':
                require.ensure([], function (require, exports, module) {
                  var scope = require('shipping-bill-lading-confirmed');
                  loadScope(scope, '#shipping-bill-lading-confirmed');
                }, loadErrorCallBack, 'shipping-bill-lading-confirmed');
                break;
              case 'driver-account-old':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-account-old');
                  loadScope(scope, '#driver-account-old');
                }, loadErrorCallBack, 'driver-account-old');
                break;
                //司机余额流水
              case 'driver-amount-flow':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-amount-flow');
                  loadScope(scope, '#driver-amount-flow');
                }, loadErrorCallBack, 'driver-amount-flow');
                break;
                //抢单列表
              case 'grab-list':
                require.ensure([], function (require, exports, module) {
                  var scope = require('grab-list');
                  loadScope(scope, '#grab-list');
                }, loadErrorCallBack, 'grab-list');
                break;
                //司机积分
              case 'driver-point':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-point');
                  loadScope(scope, '#driver-point');
                }, loadErrorCallBack, 'driver-point');
                break;
                //司机余额
              case 'driver-amount':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-amount');
                  loadScope(scope, '#driver-amount');
                }, loadErrorCallBack, 'driver-amount');
                break;
                //客户通讯
              case 'customer-communication':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-communication');
                  loadScope(scope, '#customer-communication');
                }, loadErrorCallBack, 'customer-communication');
                break;
                //预订预提放单
              case 'reserve-get-release':
                require.ensure([], function (require, exports, module) {
                  var scope = require('reserve-get-release');
                  loadScope(scope, '#reserve-get-release');
                }, loadErrorCallBack, 'reserve-get-release');
                break;
                //待指派预订预提
              case 'reserve-get-await':
                require.ensure([], function (require, exports, module) {
                  var scope = require('reserve-get-await');
                  loadScope(scope, '#reserve-get-await');
                }, loadErrorCallBack, 'reserve-get-await');
                break;
                //司机热线
              case 'driver-communication':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-communication');
                  loadScope(scope, '#driver-communication');
                }, loadErrorCallBack, 'driver-communication');
                break;
                 //专线管理
              case 'special-line':
                require.ensure([], function (require, exports, module) {
                  var scope = require('special-line');
                  loadScope(scope, '#special-line');
                }, loadErrorCallBack, 'special-line');
                break;
              //加入专线审批
              case 'special-line-driver-join-list':
                require.ensure([], function (require, exports, module) {
                  var scope = require('special-line-driver-join-list');
                  loadScope(scope, '#special-line-driver-join-list');
                }, loadErrorCallBack, 'special-line-driver-join-list');
                break;
              //退出专线审批
              case 'special-line-driver-exit-list':
                require.ensure([], function (require, exports, module) {
                  var scope = require('special-line-driver-exit-list');
                  loadScope(scope, '#special-line-driver-exit-list');
                }, loadErrorCallBack, 'special-line-driver-exit-list');
                break;
              //加入专线审批详情
              case 'special-line-driver-join':
                require.ensure([], function (require, exports, module) {
                  var scope = require('special-line-driver-join');
                  loadScope(scope, '#special-line-driver-join');
                }, loadErrorCallBack, 'special-line-driver-join');
                break;
              //退出专线审批详情
              case 'special-line-driver-exit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('special-line-driver-exit');
                  loadScope(scope, '#special-line-driver-exit');
                }, loadErrorCallBack, 'special-line-driver-exit');
                break;
                //维护堆场位置
              // case 'notify-rail-depot':
              //   require.ensure([], function (require, exports, module) {
              //     var scope = require('notify-rail-depot');
              //     loadScope(scope, '#notify-rail-depot');
              //   }, loadErrorCallBack, 'notify-rail-depot');
              //   break;
                //维护门点位置
              // case 'notify-rail-door':
              //   require.ensure([], function (require, exports, module) {
              //     var scope = require('notify-rail-door');
              //     loadScope(scope, '#notify-rail-door');
              //   }, loadErrorCallBack, 'notify-rail-door');
              //   break;
                //电话推广任务
              case 'capacity-generalize':
                require.ensure([], function (require, exports, module) {
                  var scope = require('capacity-generalize');
                  loadScope(scope, '#capacity-generalize');
                }, loadErrorCallBack, 'capacity-generalize');
                break;
                //电话推广任务详情
              case 'capacity-generalize-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('capacity-generalize-detail');
                  loadScope(scope, '#capacity-generalize-detail');
                }, loadErrorCallBack, 'capacity-generalize-detail');
                break;
                // 预付转账审核
              case 'prepayment-transfer-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('prepayment-transfer-audit');
                  loadScope(scope, '#prepayment-transfer-audit');
                }, loadErrorCallBack, 'prepayment-transfer-audit');
                break;
              //二次进港待抢单
              case 'drop-container-grab-order':
                require.ensure([], function (require, exports, module) {
                  var scope = require('drop-container-grab-order');
                  loadScope(scope, '#drop-container-grab-order');
                }, loadErrorCallBack, 'drop-container-grab-order');
                break;
                //  跟踪工厂计划
              case 'track-factory-plan':
                require.ensure([], function (require, exports, module) {
                  var scope = require('track-factory-plan');
                  loadScope(scope, '#track-factory-plan');
                }, loadErrorCallBack, 'track-factory-plan');
                break;
                //运管反馈
              case 'transporter-feedback':
                require.ensure([], function (require, exports, module) {
                  var scope = require('transporter-feedback');
                  loadScope(scope, '#transporter-feedback');
                }, loadErrorCallBack, 'transporter-feedback');
                break;

                //二次进港待确认司机抢单
              case 'drop-container-confirm-order':
                require.ensure([], function (require, exports, module) {
                  var scope = require('drop-container-confirm-order');
                  loadScope(scope, '#drop-container-confirm-order');
                }, loadErrorCallBack, 'drop-container-confirm-order');
                break;
                //邮件发送记录
              case 'maintain-mail-server':
                require.ensure([], function (require, exports, module) {
                  var scope = require('maintain-mail-server');
                  loadScope(scope, '#maintain-mail-server');
                }, loadErrorCallBack, 'maintain-mail-server');
                break;
                //短信发送记录
              case 'maintain-message-server':
                require.ensure([], function (require, exports, module) {
                  var scope = require('maintain-message-server');
                  loadScope(scope, '#maintain-message-server');
                }, loadErrorCallBack, 'maintain-message-server');
                break;
                //推广记录
              case 'promotion-record':
                require.ensure([], function (require, exports, module) {
                  var scope = require('promotion-record');
                  loadScope(scope, '#promotion-record');
                }, loadErrorCallBack, 'promotion-record');
                break;
                //货车位置，绑定申请
              case 'customer-truck-binding':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-truck-binding');
                  loadScope(scope, '#customer-truck-binding');
                }, loadErrorCallBack, 'customer-truck-binding');
                break;
                //货车位置，屏蔽名单
              case 'customer-truck-block':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-truck-block');
                  loadScope(scope, '#customer-truck-block');
                }, loadErrorCallBack, 'customer-truck-block');
                break;
                //货车位置，数据统计
              case 'customer-truck-count':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-truck-count');
                  loadScope(scope, '#customer-truck-count');
                }, loadErrorCallBack, 'customer-truck-count');
                break;
              // 可用运力预估
              case 'capacity-estimation':
                require.ensure([], function (require, exports, module) {
                  var scope = require('capacity-estimation');
                  loadScope(scope, '#capacity-estimation');
                }, loadErrorCallBack, 'capacity-estimation');
                break;
                //旺时&节日设置'
              case 'peak-day-config':
                require.ensure([], function (require, exports, module) {
                  var scope = require('peak-day-config');
                  loadScope(scope, '#peak-day-config');
                }, loadErrorCallBack, 'peak-day-config');
                break;
                // 基础字段仓库
                case 'base-data-warehouse':
                    require.ensure([], function (require, exports, module) {
                        var scope = require('base-data-warehouse');
                        loadScope(scope, '#base-data-warehouse');
                    }, loadErrorCallBack, 'base-data-warehouse');
                    break;
                //维护中转港
              case 'maintain-transit-port':
                require.ensure([], function (require, exports, module) {
                  var scope = require('maintain-transit-port');
                  loadScope(scope, '#maintain-transit-port');
                }, loadErrorCallBack, 'maintain-transit-port');
                break;

                //单证操作
              case 'document-operate':
                require.ensure([], function (require, exports, module) {
                  var scope = require('document-operate');
                  loadScope(scope, '#document-operate');
                }, loadErrorCallBack, 'document-operate');
                break;
              case 'receipt-recovery':
                require.ensure([], function (require, exports, module) {
                  var scope = require('receipt-recovery');
                  loadScope(scope, '#receipt-recovery');
                }, loadErrorCallBack, 'receipt-recovery');
                break;
                //提箱迟到
              case 'pickup-late':
                require.ensure([], function (require, exports, module) {
                  var scope = require('pickup-late');
                  loadScope(scope, '#pickup-late');
                }, loadErrorCallBack, 'pickup-late');
                break;
                //到厂迟到
              case 'planArrival-late':
                require.ensure([], function (require, exports, module) {
                  var scope = require('planArrival-late');
                  loadScope(scope, '#planArrival-late');
                }, loadErrorCallBack, 'planArrival-late');
                break;
                //装箱异常
              case 'install-container-abnormal':
                require.ensure([], function (require, exports, module) {
                  var scope = require('install-container-abnormal');
                  loadScope(scope, '#install-container-abnormal');
                }, loadErrorCallBack, 'install-container-abnormal');
                break;
                //空闲vip
              case "free-vip":
                require.ensure([], function (require, exports, module) {
                  var scope = require("free-vip")
                  scope.init("#free-vip")
                }, loadErrorCallBack, "free-vip");
                break;
                //可调整司机
              case "adjustable-driver":
                require.ensure([], function (require, exports, module) {
                  var scope = require("adjustable-driver")
                  scope.init("#adjustable-driver")
                }, loadErrorCallBack, "adjustable-driver");
                break;
                //司机黑名单
              case "driver-blacklist":
                require.ensure([], function (require, exports, module) {
                  var scope = require("driver-blacklist")
                  scope.init("#driver-blacklist")
                }, loadErrorCallBack, "driver-blacklist");
                break;
              // 司机灰度名单(业财一体)
              case "version-driver-whitelist":
                require.ensure([], function (require, exports, module) {
                  var scope = require("version-driver-whitelist")
                  scope.init("#version-driver-whitelist")
                }, loadErrorCallBack, "version-driver-whitelist");
                break;
                //拉黑审核
              case 'blocking-driver-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('blocking-driver-audit');
                  loadScope(scope, '#blocking-driver-audit');
                }, loadErrorCallBack, 'blocking-driver-audit');
              break;
                /**
                 * 违章处理下线
                */
                // 违规司机处理
              case 'complain-about-driver':
                require.ensure([], function (require, exports, module) {
                  var scope = require('complain-about-driver');
                  loadScope(scope, '#complain-about-driver');
                }, loadErrorCallBack, 'complain-about-driver');
              break;
                // 船期维护
              case "ship-maintenance":
                require.ensure([], function (require, exports, module) {
                  var scope = require("ship-maintenance")
                  scope.init("#ship-maintenance")
                }, loadErrorCallBack, "ship-maintenance");
                break;

                //司机北斗信号状态
              case "driver-location-state":
                require.ensure([], function (require, exports, module) {
                  var scope = require("driver-location-state")
                  scope.init("#driver-location-state")
                }, loadErrorCallBack, "driver-location-state");
                break;

                //内部协同
              case 'internal-coordination':
                require.ensure([], function (require, exports, module) {
                  var scope = require('internal-coordination');
                  loadScope(scope, '#internal-coordination');
                }, loadErrorCallBack, 'internal-coordination');
                break;
                //运力审核
              case 'excess-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('excess-audit');
                  loadScope(scope, '#excess-audit');
                }, loadErrorCallBack, 'excess-audit');
                break;
                //标准报价查询
              case 'standard-price-query':
                require.ensure([], function (require, exports, module) {
                  var scope = require('standard-price-query');
                  loadScope(scope, '#standard-price-query');
                }, loadErrorCallBack, 'standard-price-query');
                break;

                //调度管理
              case 'dispatch-manage':
                require.ensure([], function (require, exports, module) {
                  var scope = require('dispatch-manage');
                  loadScope(scope, '#dispatch-manage');
                }, loadErrorCallBack, 'dispatch-manage');
                break;

                //奖券管理
              case 'coupon-manage':
                require.ensure([], function (require, exports, module) {
                  var scope = require('coupon-manage');
                  loadScope(scope, '#coupon-manage');
                }, loadErrorCallBack, 'coupon-manage');
                break;
                //商务标准报价
              case 'business-standard-price':
                require.ensure([], function (require, exports, module) {
                  var scope = require('business-standard-price');
                  loadScope(scope, '#business-standard-price');
                }, loadErrorCallBack, 'business-standard-price');
                break;
              //应付运费定价
              case 'freight-pricing':
                require.ensure([], function (require, exports, module) {
                  var scope = require('freight-pricing');
                  loadScope(scope, '#freight-pricing');
                }, loadErrorCallBack, 'freight-pricing');
                break;
              //附加费用规则
              case 'surcharge-rules':
                require.ensure([], function (require, exports, module) {
                  var scope = require('surcharge-rules');
                  loadScope(scope, '#surcharge-rules');
                }, loadErrorCallBack, 'surcharge-rules');
                break;
                //ETC标准报价
              case 'etc-standard-price':
                require.ensure([], function (require, exports, module) {
                  var scope = require('etc-standard-price');
                  loadScope(scope, '#etc-standard-price');
                }, loadErrorCallBack, 'etc-standard-price');
                break;
                // 失信黑名单
              case 'black-menu':
                require.ensure([], function (require, exports, module) {
                  var scope = require('black-menu');
                  loadScope(scope, '#black-menu');
                }, loadErrorCallBack, 'black-menu');
                break;
                //异常费用公告编辑
              case 'port-charges':
                require.ensure([], function (require, exports, module) {
                  var scope = require('port-charges');
                  loadScope(scope, '#port-charges');
                }, loadErrorCallBack, 'port-charges');
                break;
                //客户额度预警
              case 'quota-warning':
                require.ensure([], function (require, exports, module) {
                  var scope = require('quota-warning');
                  loadScope(scope, '#quota-warning');
                }, loadErrorCallBack, 'quota-warning');
                break;
                //顺风车订阅查询
              case 'free-riding-subscription-query':
                require.ensure([], function (require, exports, module) {
                  var scope = require('free-riding-subscription-query');
                  loadScope(scope, '#free-riding-subscription-query');
                }, loadErrorCallBack, 'free-riding-subscription-query');
                break;
                //客户订车
              case 'customer-ordering-car':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-ordering-car');
                  loadScope(scope, '#customer-ordering-car');
                }, loadErrorCallBack, 'customer-ordering-car');
                break;
                //发送发票
              case 'invoice-sending':
                require.ensure([], function (require, exports, module) {
                  var scope = require('invoice-sending');
                  loadScope(scope, '#invoice-sending');
                }, loadErrorCallBack, 'invoice-sending');
                break;
             //进项发票上传
              case 'input-invoice':
                require.ensure([], function (require, exports, module) {
                  var scope = require('input-invoice');
                  loadScope(scope, '#input-invoice');
                }, loadErrorCallBack, 'input-invoice');
                break;
                //意向购车
              case 'intention-buycar':
                require.ensure([], function (require, exports, module) {
                  var scope = require('intention-buycar');
                  loadScope(scope, '#intention-buycar');
                }, loadErrorCallBack, 'intention-buycar');
                break;
                // 每日箱量额度设置
              case 'daily-quota-setting':
                require.ensure([], function (require, exports, module) {
                  var scope = require('daily-quota-setting');
                  loadScope(scope, '#daily-quota-setting');
                }, loadErrorCallBack, 'daily-quota-setting');
                break;
                //商务标准报价
              case 'coustomer-door-check':
                require.ensure([], function (require, exports, module) {
                  var scope = require('coustomer-door-check');
                  loadScope(scope, '#coustomer-door-check');
                }, loadErrorCallBack, 'coustomer-door-check');
                break;
                //无纸化未派车
              case 'paperless-nocar':
                require.ensure([], function (require, exports, module) {
                  var scope = require('paperless-nocar');
                  loadScope(scope, '#paperless-nocar');
                }, loadErrorCallBack, 'paperless-nocar');
                break;
                //客服退关
              case 'customer-shut':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-shut');
                  loadScope(scope, '#customer-shut');
                }, loadErrorCallBack, 'customer-shut');
                break;
                // 运价查询日志
              case 'transport-price-query':
                require.ensure([], function (require, exports, module) {
                  var scope = require('transport-price-query');
                  loadScope(scope, '#transport-price-query');
                }, loadErrorCallBack, 'transport-price-query');
                break;
                // 客户询价参数设置
              case 'business-parameters':
                require.ensure([], function (require, exports, module) {
                  var scope = require('business-parameters');
                  loadScope(scope, '#business-parameters');
                }, loadErrorCallBack, 'business-parameters');
                break;



                //待接单（在线订单）
              case 'wait-order-receiving-newOrder':
                require.ensure([], function (require, exports, module) {
                  var scope = require('wait-order-receiving-newOrder');
                  loadScope(scope, '#wait-order-receiving-newOrder');
                }, loadErrorCallBack, 'wait-order-receiving-newOrder');
                break;

                //客服异常跟踪
              case 'customer-abnormal-tracking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-abnormal-tracking');
                  loadScope(scope, '#customer-abnormal-tracking');
                }, loadErrorCallBack, 'customer-abnormal-tracking');
                break;
                // 运管异常跟踪
              case 'transport-abnormal-tracking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('transport-abnormal-tracking');
                  loadScope(scope, '#transport-abnormal-tracking');
                }, loadErrorCallBack, 'transport-abnormal-tracking');
                break;
                //   客户投诉
              case 'customer-complaints':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-complaints');
                  loadScope(scope, '#customer-complaints');
                }, loadErrorCallBack, 'customer-complaints');
                break;
                //客户评价
              case 'customer-evaluate':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-evaluate');
                  loadScope(scope, '#customer-evaluate');
                }, loadErrorCallBack, 'customer-evaluate');
                break;
                //   客户公海
              case 'customer-conversion':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-conversion');
                  loadScope(scope, '#customer-conversion');
                }, loadErrorCallBack, 'customer-conversion');
                break;
                //   智能推送记录
              case 'smart-dispatch-log':
                require.ensure([], function (require, exports, module) {
                  var scope = require('smart-dispatch-log');
                  loadScope(scope, '#smart-dispatch-log');
                }, loadErrorCallBack, 'smart-dispatch-log');
                break;
              // case 'customer-change-detail':
              //   require.ensure([], function (require, exports, module) {
              //     var scope = require('customer-change-detail');
              //     loadScope(scope, '#customer-change-detail');
              //   }, loadErrorCallBack, 'customer-change-detail');
              //   break;
                //缺箱跟踪
              case 'box-tracking':
                require.ensure([], function (require, exports, module) {
                  var scope = require('box-tracking');
                  loadScope(scope, '#box-tracking');
                }, loadErrorCallBack, 'box-tracking');
                break;
                //流失客户
              case 'losing-customer':
                require.ensure([], function (require, exports, module) {
                  var scope = require('losing-customer');
                  loadScope(scope, '#losing-customer');
                }, 'losing-customer');
                // 新流失客户
                case 'lost-customers':
                  require.ensure([], function (require, exports, module) {
                    var scope = require('lost-customers');
                    loadScope(scope, '#lost-customers');
                  }, 'lost-customers');
                break;
                   // 新流失客户分析
                case 'lost-customers-analysis':
                    require.ensure([], function (require, exports, module) {
                      var scope = require('lost-customers-analysis');
                      loadScope(scope, '#lost-customers-analysis');
                    }, 'lost-customers-analysis');
                 break;
                // 个人注册客户
                // case 'personal-register':
                //     require.ensure([], function (require, exports, module) {
                //         var scope = require('personal-register');
                //         loadScope(scope, '#personal-register');
                //     }, 'personal-register');
                //     break;
                //个人注册审核
              case 'register-review-person':
                require.ensure([], function (require, exports, module) {
                  var scope = require('register-review-person');
                  loadScope(scope, '#register-review-person');
                }, 'register-review-person');
                break;
                //进口未排计划
              case 'unscheduled-imported':
                require.ensure([], function (require, exports, module) {
                  var scope = require('unscheduled-imported');
                  loadScope(scope, '#unscheduled-imported');
                }, 'unscheduled-imported');
                break;
                //客户询价
              case 'customer-query':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-query');
                  loadScope(scope, '#customer-query');
                }, 'customer-query');
                break;
                //客户做箱质量评价统计
              case 'container-quality-evaluate':
                require.ensure([], function (require, exports, module) {
                  var scope = require('container-quality-evaluate');
                  loadScope(scope, '#container-quality-evaluate');
                }, 'container-quality-evaluate');
                break;
                //预进港状态统计
              case 'advance-port-count':
                require.ensure([], function (require, exports, module) {
                  var scope = require('advance-port-count');
                  loadScope(scope, '#advance-port-count');
                }, loadErrorCallBack, 'advance-port-count');
                break;
                //中秋活动礼品发放
              case 'moon-search':
                require.ensure([], function (require, exports, module) {
                  var scope = require('moon-search');
                  loadScope(scope, '#moon-search');
                }, loadErrorCallBack, 'moon-search');
                break;
                //附件设置
              case 'attachment-setting':
                require.ensure([], function (require, exports, module) {
                  var scope = require('attachment-setting');
                  loadScope(scope, '#attachment-setting');
                }, loadErrorCallBack, 'attachment-setting');
                break;
                //宁波无纸化
              case 'serialnum-is-ningbo':
                require.ensure([], function (require, exports, module) {
                  var scope = require('serialnum-is-ningbo');
                  loadScope(scope, '#serialnum-is-ningbo');
                }, loadErrorCallBack, 'serialnum-is-ningbo');
                break;
                //议价审核
              case 'bargaining-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('bargaining-audit');
                  loadScope(scope, '#bargaining-audit');
                }, loadErrorCallBack, 'bargaining-audit');
                break;
                //司机还款计划
              case 'driver-repayment-plan':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-repayment-plan');
                  loadScope(scope, '#driver-repayment-plan');
                }, loadErrorCallBack, 'driver-repayment-plan');
                break;
                // 司机还款申请
              case 'driver-repayment-apply':
                require.ensure([],function (require, exports, module) {
                  var scope = require('driver-repayment-apply');
                  loadScope(scope, '#driver-repayment-apply');
                },loadErrorCallBack, 'driver-repayment-apply');
                break;
                //公告编辑
              case 'notice-editor':
                require.ensure([], function (require, exports, module) {
                  var scope = require('notice-editor');
                  loadScope(scope, '#notice-editor');
                }, loadErrorCallBack, 'notice-editor');
                break;
                //分享图片配置
              case 'share-image-config':
                require.ensure([], function (require, exports, module) {
                  var scope = require('share-image-config');
                  loadScope(scope, '#share-image-config');
                }, loadErrorCallBack, 'share-image-config');
                break;
                //群发短信
              case 'group-message':
                require.ensure([], function (require, exports, module) {
                  var scope = require('group-message');
                  loadScope(scope, '#group-message');
                }, loadErrorCallBack, 'group-message');
                break;
                //旺季策略配置
              case 'season-strategy-setting':
                require.ensure([], function (require, exports, module) {
                  var scope = require('season-strategy-setting');
                  loadScope(scope, '#season-strategy-setting');
                }, loadErrorCallBack, 'season-strategy-setting');
                break;
                //新建旺季配置、旺季配置详情
              case 'strategy-setting-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('strategy-setting-detail');
                  loadScope(scope, '#strategy-setting-detail');
                }, loadErrorCallBack, 'strategy-setting-detail');
                break;
                //旺季策略-客户名单
              case 'season-strategy-customerLists':
                require.ensure([], function (require, exports, module) {
                  var scope = require('season-strategy-customerLists');
                  loadScope(scope, '#season-strategy-customerLists');
                }, loadErrorCallBack, 'season-strategy-customerLists');
                break;
                //报关管理
              case 'declare-manage':
                require.ensure([], function (require, exports, module) {
                  var scope = require('declare-manage');
                  loadScope(scope, '#declare-manage');
                }, loadErrorCallBack, 'declare-manage');
                break;
                // 积分商品
              case 'user-point-goods':
                require.ensure([], function (require, exports, module) {
                  var scope = require('user-point-goods');
                  loadScope(scope, '#user-point-goods');
                }, loadErrorCallBack, 'user-point-goods');
                break;
              // 客服电话
              case 'phone-records':
                require.ensure([], function (require, exports, module) {
                  var scope = require('phone-records');
                  loadScope(scope, '#phone-records');
                }, loadErrorCallBack, 'phone-records');
                break;
              // 现金单港口运费比例配置
              case 'cash-payable':
                require.ensure([], function (require, exports, module) {
                  var scope = require('cash-payable');
                  loadScope(scope, '#cash-payable');
                }, loadErrorCallBack, 'cash-payable');
                break;
                // 旺季额度设置
              case 'peak-season-quota':
                require.ensure([], function (require, exports, module) {
                  var scope = require('peak-season-quota');
                  loadScope(scope, '#peak-season-quota');
                }, loadErrorCallBack, 'peak-season-quota');
                break;
                // 旺季额度设置详情
              case 'peak-season-quota-details':
                require.ensure([], function (require, exports, module) {
                  var scope = require('peak-season-quota-details');
                  loadScope(scope, '#peak-season-quota-details');
                }, loadErrorCallBack, 'peak-season-quota-details');
                break;
                // 积分商品兑换记录
              case 'user-point-exchange-record':
                require.ensure([], function (require, exports, module) {
                  var scope = require('user-point-exchange-record');
                  loadScope(scope, '#user-point-exchange-record');
                }, loadErrorCallBack, 'user-point-exchange-record');
                break;
                // 活动中心
              case 'activity-center':
                require.ensure([], function (require, exports, module) {
                  var scope = require('activity-center');
                  loadScope(scope, '#activity-center');
                }, loadErrorCallBack, 'activity-center');
                break;
                // 司机福利
              case 'driver-welfare':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-welfare');
                  loadScope(scope, '#driver-welfare');
                }, loadErrorCallBack, 'driver-welfare');
                break;
                // 奖/助学金
              case 'scholarship-apply':
                require.ensure([], function (require, exports, module) {
                  var scope = require('scholarship-apply');
                  loadScope(scope, '#scholarship-apply');
                }, loadErrorCallBack, 'scholarship-apply');
                break;
                // 司机热线详情
              case 'driver-communication-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('driver-communication-detail');
                  loadScope(scope, '#driver-communication-detail');
                }, loadErrorCallBack, 'driver-communication-detail')
                break;
                // 客户驳回费用确认
                case 'customer-fee-reject-audit':
                require.ensure([],function (require, exports, module) {
                    var scope = require('customer-fee-reject-audit');
                    loadScope(scope, '#customer-fee-reject-audit');
                }, loadErrorCallBack,'customer-fee-reject-audit')
                break;
            // 工厂超额装卸
            case 'factory-overloading':
                require.ensure([],function (require, exports, module) {
                    var scope = require('factory-overloading');
                    loadScope(scope, '#factory-overloading');
                }, loadErrorCallBack,'factory-overloading')
                break;
              case 'fee-reduction-review':
                require.ensure([], function (require, exports, module) {
                  var scope = require('fee-reduction-review');
                  loadScope(scope, '#fee-reduction-review');
                }, loadErrorCallBack, 'fee-reduction-review')
                break;
              case 'security-incident':
              require.ensure([],function (require, exports, module) {
                  var scope = require('security-incident');
                  loadScope(scope, '#security-incident');
              }, loadErrorCallBack,'security-incident')
              break;
				// 司机热线详情（新）
				case 'driver-communication-new':
						require.ensure([], function (require, exports, module) {
							var scope = require('driver-communication-new');
							loadScope(scope, '#driver-communication-new');
						}, loadErrorCallBack,'driver-communication-new');
        break;
        				// 催箱货信息
				case 'urgegoodsinfo':
          require.ensure([], function (require, exports, module) {
            var scope = require('urgegoodsinfo');
            loadScope(scope, '#urgegoodsinfo');
          }, loadErrorCallBack,'urgegoodsinfo');
        break;
        //做箱门点审核
        case 'coustomer-door-container-check':
          require.ensure([], function (require, exports, module) {
            var scope = require('coustomer-door-container-check');
            loadScope(scope, '#coustomer-door-container-check');
          }, loadErrorCallBack,'coustomer-door-container-check');
        break;
        //新工厂信息采集（工厂）
        case 'factory-information-collection':
          require.ensure([], function (require, exports, module) {
            var scope = require('factory-information-collection');
            loadScope(scope, '#factory-information-collection');
          }, loadErrorCallBack,'factory-information-collection');
        break;
        //新工厂信息采集（司机）
        case 'factory-information-collection-driver':
          require.ensure([], function (require, exports, module) {
            var scope = require('factory-information-collection-driver');
            loadScope(scope, '#factory-information-collection-driver');
          }, loadErrorCallBack,'factory-information-collection-driver');
        break;
        //新工厂信息审核
        case 'factory-information-collection-audit':
          require.ensure([], function (require, exports, module) {
            var scope = require('factory-information-collection-audit');
            loadScope(scope, '#factory-information-collection-audit');
          }, loadErrorCallBack,'factory-information-collection-audit');
        break;
         //工厂档案
         case 'factory-archives':
          require.ensure([], function (require, exports, module) {
            var scope = require('factory-archives');
            loadScope(scope, '#factory-archives');
          }, loadErrorCallBack,'factory-archives');
        break;
        case 'abnormal-expenses':
          require.ensure([], function (require, exports, module) {
            var scope = require('abnormal-expenses');
            loadScope(scope, '#abnormal-expenses');
          }, loadErrorCallBack,'abnormal-expenses');
        break;
        case 'expense-confirmation-management':
          require.ensure([], function (require, exports, module) {
            var scope = require('expense-confirmation-management');
            loadScope(scope, '#expense-confirmation-management');
          }, loadErrorCallBack,'expense-confirmation-management');
        break;
        // 旺季&额度设置
        case 'hot-date-set':
          require.ensure([], function (require, exports, module) {
            var scope = require('hot-date-set');
            loadScope(scope, '#hot-date-set');
          }, loadErrorCallBack,'hot-date-set');
        break;
        // 多地装价格设置
        case 'multiple-locations-price-settings':
          require.ensure([], function (require, exports, module) {
            var scope = require('multiple-locations-price-settings');
            loadScope(scope, '#multiple-locations-price-settings');
          }, loadErrorCallBack, 'multiple-locations-price-settings');
        break;
          // 通行证办理
        case 'forbidden-permit':
          require.ensure([], function (require, exports, module) {
            var scope = require('forbidden-permit');
            loadScope(scope, '#forbidden-permit');
          }, loadErrorCallBack,'forbidden-permit');
          break;

        // 多地装价格查询
        case 'multiple-packing':
          require.ensure([], function (require, exports, module) {
            var scope = require('multiple-packing');
            loadScope(scope, '#multiple-packing');
          }, loadErrorCallBack,'multiple-packing');
        break;
        case 'bd-user-management':
          require.ensure([], function (require, exports, module) {
            var scope = require('bd-user-management');
            loadScope(scope, '#bd-user-management');
          }, loadErrorCallBack, 'bd-user-management');
        break;
        // 进港清单
        case 'drop-container-gate-list':
          require.ensure([], function (require, exports, module) {
            var scope = require('drop-container-gate-list');
            loadScope(scope, '#drop-container-gate-list');
          }, loadErrorCallBack, 'drop-container-gate-list');
        // 客户定制模板
        case 'customize-customer-bill-template':
          require.ensure([], function (require, exports, module) {
            var scope = require('customize-customer-bill-template');
            loadScope(scope, '#customize-customer-bill-template');
          }, loadErrorCallBack, 'customize-customer-bill-template');
        break;
        // 运输跟踪
        case 'container-track':
          require.ensure([], function (require, exports, module) {
            var scope = require('container-track');
            loadScope(scope, '#container-track');
          }, loadErrorCallBack, 'container-track');
        break;
        //合同模板设置
        case 'contract-modal':
          require.ensure([], function (require, exports, module) {
            var scope = require('contract-modal');
            loadScope(scope, '#contract-modal');
          }, loadErrorCallBack, 'contract-modal');
        break;
        // 客户线下合同申请
        case 'offline-contract-apply':
          require.ensure([], function (require, exports, module) {
            var scope = require('offline-contract-apply');
            loadScope(scope, '#offline-contract-apply');
          }, loadErrorCallBack, 'offline-contract-apply');
        break;
        // 核酸检测
        case 'nucleic-acid-detection':
          require.ensure([], function (require, exports, module) {
            var scope = require('nucleic-acid-detection');
            loadScope(scope, '#nucleic-acid-detection');
          }, loadErrorCallBack, 'nucleic-acid-detection');
        break;
        // 短驳任务跟踪
        case 'drayage-task-trace':
          require.ensure([], function (require, exports, module) {
            var scope = require('drayage-task-trace');
            loadScope(scope, '#drayage-task-trace');
          }, loadErrorCallBack, 'drayage-task-trace');
        break;
        // 异常费用定责
        case 'abnormal-expenses-liability':
          require.ensure([], function (require, exports, module) {
            var scope = require('abnormal-expenses-liability');
            loadScope(scope, '#abnormal-expenses-liability');
          }, loadErrorCallBack, 'abnormal-expenses-liability');
        break;
        // 电话推广记录
        case 'phone-promotion':
          require.ensure([], function (require, exports, module) {
            var scope = require('phone-promotion');
            loadScope(scope, '#phone-promotion');
          }, loadErrorCallBack, 'phone-promotion');
        break;
        // 日账单列表
        case 'pbp-dailybill-list':
          require.ensure([], function (require, exports, module) {
            var scope = require('pbp-dailybill-list');
            loadScope(scope, '#pbp-dailybill-list');
          }, loadErrorCallBack, 'pbp-dailybill-list');
        break;
        // 日账单详情
        case 'pbp-dailybill-detail':
          require.ensure([], function (require, exports, module) {
            var scope = require('pbp-dailybill-detail');
            loadScope(scope, '#pbp-dailybill-detail');
          }, loadErrorCallBack, 'pbp-dailybill-detail');
        break;
        // 江海小程序运单列表
        case 'pbp-waybill-management':
          require.ensure([], function (require, exports, module) {
            var scope = require('pbp-waybill-management');
            loadScope(scope, '#pbp-waybill-management');
          }, loadErrorCallBack, 'pbp-waybill-management');
        break;
        // 江海小程序运单列表
        case 'pbp-pay-management':
          require.ensure([], function (require, exports, module) {
            var scope = require('pbp-pay-management');
            loadScope(scope, '#pbp-pay-management');
          }, loadErrorCallBack, 'pbp-pay-management');
        break;
        // 驾驶员预约
        case 'driver-reservation':
          require.ensure([], function (require, exports, module) {
            var scope = require('driver-reservation');
            loadScope(scope, '#driver-reservation');
          }, loadErrorCallBack, 'driver-reservation');
          break;
        // 客服组管理
        case 'customer-service-group-management':
          require.ensure([], function (require, exports, module) {
            var scope = require('customer-service-group-management');
            loadScope(scope, '#customer-service-group-management');
          }, loadErrorCallBack, 'customer-service-group-management');
        break;
        // 客服电话值班
        case 'customer-service-duty':
          require.ensure([], function (require, exports, module) {
            var scope = require('customer-service-duty');
            loadScope(scope, '#customer-service-duty');
          }, loadErrorCallBack, 'customer-service-duty');
        break;
        // 夜班客服排班
        case 'night-customer-service-duty':
          require.ensure([], function (require, exports, module) {
            var scope = require('night-customer-service-duty');
            loadScope(scope, '#night-customer-service-duty');
          }, loadErrorCallBack, 'night-customer-service-duty');
        break;
         // 坐席在线监控
        case 'at-online-monitoring':
          require.ensure([], function (require, exports, module) {
            var scope = require('at-online-monitoring');
            loadScope(scope, '#at-online-monitoring');
          }, loadErrorCallBack, 'at-online-monitoring');
        break;
         // 代班管理
         case 'agent-class-management':
          require.ensure([], function (require, exports, module) {
            var scope = require('agent-class-management');
            loadScope(scope, '#agent-class-management');
          }, loadErrorCallBack, 'agent-class-management');
        break;
        //司机审批
        case 'driver-approve':
          require.ensure([], function (require, exports, module) {
            var scope = require('driver-approve');
            loadScope(scope, '#driver-approve');
          }, loadErrorCallBack, 'driver-approve');
          break;
        // 短驳调度
        case 'wait-arrange-harbour-shortBarge':
          require.ensure([], function (require, exports, module) {
            var scope = require('wait-arrange-harbour-shortBarge');
            loadScope(scope, '#wait-arrange-harbour-shortBarge');
          }, loadErrorCallBack, 'wait-arrange-harbour-shortBarge');
          break;
        // 未出勤回访
        case 'no-attendance-visit':
          require.ensure([], function (require, exports, module) {
            var scope = require('no-attendance-visit');
            loadScope(scope, '#no-attendance-visit');
          }, loadErrorCallBack, 'no-attendance-visit');
          break;
        // 抢退单记录查询
        case 'rush-refund-record':
          require.ensure([], function (require, exports, module) {
            var scope = require('rush-refund-record');
            loadScope(scope, '#rush-refund-record');
          }, loadErrorCallBack, 'rush-refund-record');
        // 回单点维护
        case 'receipt-point-maintain':
          require.ensure([], function (require, exports, module) {
            var scope = require('receipt-point-maintain');
            loadScope(scope, '#receipt-point-maintain');
          }, loadErrorCallBack, 'receipt-point-maintain');
          break;
        // 补产值管理 
        case 'compensate-business-list':
          require.ensure([], function (require, exports, module) {
            var scope = require('compensate-business-list');
            loadScope(scope, '#compensate-business-list');
          }, loadErrorCallBack, 'compensate-business-list');
          break;
        // 预录重做
        case 'prerecord-remould':
          require.ensure([], function (require, exports, module) {
            var scope = require('prerecord-remould');
            loadScope(scope, '#prerecord-remould');
          }, loadErrorCallBack, 'prerecord-remould');
          break;
        // 理单任务
        case 'tally-sheet-task':
          require.ensure([], function (require, exports, module) {
            var scope = require('tally-sheet-task');
            loadScope(scope, '#tally-sheet-task');
          }, loadErrorCallBack, 'tally-sheet-task');
          break;
        // 放箱任务
        // case 'release-container-task':
        //   require.ensure([], function (require, exports, module) {
        //     var scope = require('release-container-task');
        //     loadScope(scope, '#release-container-task');
        //   }, loadErrorCallBack, 'release-container-task');
        //   break;
        // 驳船任务
        case 'release-container-task':
          require.ensure([], function (require, exports, module) {
            var scope = require('release-container-task');
            loadScope(scope, '#release-container-task');
          }, loadErrorCallBack, 'release-container-task');
          break;
        // 放箱订单
        case 'release-container-order':
          require.ensure([], function (require, exports, module) {
            var scope = require('release-container-order');
            loadScope(scope, '#release-container-order');
          }, loadErrorCallBack, 'release-container-order');
          break;
        // 放箱规则配置
        case 'release-container-config':
          require.ensure([], function (require, exports, module) {
            var scope = require('release-container-config');
            loadScope(scope, '#release-container-config');
          }, loadErrorCallBack, 'release-container-config');
          break;
        //  补业务管理
        // 补业务
        case 'compensate-practice-manage':
            require.ensure([], function (require, exports, module) {
              var scope = require('compensate-practice-manage');
              loadScope(scope, '#compensate-practice-manage');
          }, loadErrorCallBack, 'compensate-practice-manage');
          break;
        // 补产值
        case 'no-send-cars':
          require.ensure([], function (require, exports, module) {
            var scope = require('no-send-cars');
            loadScope(scope, '#no-send-cars');
          }, loadErrorCallBack, 'no-send-cars');
          break;
        // 设备单管理
        case 'eir-manage':
          require.ensure([], function (require, exports, module) {
            var scope = require('eir-manage');
            loadScope(scope, '#eir-manage');
          }, loadErrorCallBack, 'eir-manage');
          break;
        // 待司机确认接单
        case 'container-confirm-order-driver':
          require.ensure([], function (require, exports, module) {
            var scope = require('container-confirm-order-driver');
            loadScope(scope, '#container-confirm-order-driver');
          }, loadErrorCallBack, 'container-confirm-order-driver');
          break;
        // 关照司机
        case 'look-after-driver':
          require.ensure([], function (require, exports, module) {
            var scope = require('look-after-driver');
            loadScope(scope, '#look-after-driver');
          }, loadErrorCallBack, 'look-after-driver');
          break;
        // 关照司机管理
        case 'look-after-driver-admin':
          require.ensure([], function (require, exports, module) {
            var scope = require('look-after-driver-admin');
            loadScope(scope, '#look-after-driver-admin');
          }, loadErrorCallBack, 'look-after-driver-admin');
          break;
        // 宁波白名单
        case 'ningbo-white-list':
          require.ensure([], function (require, exports, module) {
            var scope = require('ningbo-white-list');
            loadScope(scope, '#ningbo-white-list');
          }, loadErrorCallBack, 'ningbo-white-list');
          break;
          // 批量导入
          case 'batch-production-order':
            require.ensure([], function (require, exports, module) {
              var scope = require('batch-production-order');
              loadScope(scope, '#batch-production-order');
            }, loadErrorCallBack, 'batch-production-order');
            break;
          //   合同首签
          case 'sign-contract':
            require.ensure([], function (require, exports, module) {
              var scope = require('sign-contract');
              loadScope(scope, '#sign-contract');
            }, loadErrorCallBack, 'sign-contract');
            break;
          //   合同续签
          case 'renewal-contract':
            require.ensure([], function (require, exports, module) {
              var scope = require('renewal-contract');
              loadScope(scope, '#renewal-contract');
            }, loadErrorCallBack, 'renewal-contract');
            break;
            // 异常订单
            case 'abnormal-cost':
              require.ensure([], function (require, exports, module) {
                var scope = require('abnormal-cost');
                loadScope(scope, '#abnormal-cost');
              }, loadErrorCallBack, 'abnormal-cost');
              break;
            // 异常订单费用核查
            case 'abnormal-cost-handle':
              require.ensure([], function (require, exports, module) {
                var scope = require('abnormal-cost-handle');
                loadScope(scope, '#abnormal-cost-handle');
              }, loadErrorCallBack, 'abnormal-cost-handle');
              break;
          case 'small-container-adjustment':
            require.ensure([], function (require, exports, module) {
              var scope = require('small-container-adjustment');
              loadScope(scope, '#small-container-adjustment');
            }, loadErrorCallBack, 'small-container-adjustment');
            break;
               // 发单方平台配置
            case 'platform-configuration':
              require.ensure([], function (require, exports, module) {
                var scope = require('platform-configuration');
                loadScope(scope, '#platform-configuration');
              }, loadErrorCallBack, 'platform-configuration');
              break;
               // 接单人与接单平台签约记录
            case 'signing-record':
              require.ensure([], function (require, exports, module) {
                var scope = require('signing-record');
                loadScope(scope, '#signing-record');
              }, loadErrorCallBack, 'signing-record');
              break;
            //编辑合同价
            case 'edit-customer-contract-price':
              require.ensure([], function (require, exports, module) {
                var scope = require('edit-customer-contract-price');
                loadScope(scope, '#edit-customer-contract-price');
              }, loadErrorCallBack, 'edit-customer-contract-price');
              break;
            //新增合同价
            case 'newAdd-customer-contract-price':
              require.ensure([], function (require, exports, module) {
                var scope = require('newAdd-customer-contract-price');
                loadScope(scope, '#newAdd-customer-contract-price');
              }, loadErrorCallBack, 'newAdd-customer-contract-price');
              break;
              //客户档案管理(旧)  线上保留(货重区间验证用)!
            case 'customer-manage-sub':
              require.ensure([], function (require, exports, module) {
                var scope = require('customer-manage-sub');
                loadScope(scope, '#customer-manage-sub');
              }, loadErrorCallBack, 'customer-manage-sub');
              break;
            //专线司机产值
            case 'output-line-driver':
              require.ensure([], function (require, exports, module) {
                var scope = require('output-line-driver');
                loadScope(scope, '#output-line-driver');
              }, loadErrorCallBack, 'output-line-driver');
              break;
            //合同缓签审批
            case 'contract-exemption':
              require.ensure([], function (require, exports, module) {
                var scope = require('contract-exemption');
                loadScope(scope, '#contract-exemption');
              }, loadErrorCallBack, 'contract-exemption');
              break;
            //合同申请管理
            case 'contract-apply-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('contract-apply-management');
                loadScope(scope, '#contract-apply-management');
              }, loadErrorCallBack, 'contract-apply-management');
              break;
            //合同换签待审批 其实是一个页面 但是因为jquery修改时会闪一下 新建一个项目
            case 'contract-apply-exempt-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('contract-apply-exempt-management');
                loadScope(scope, '#contract-apply-exempt-management');
              }, loadErrorCallBack, 'contract-apply-exempt-management');
              break;
            // 供应商合同申请
            case 'supplier-contract-apply-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('supplier-contract-apply-management');
                loadScope(scope, '#supplier-contract-apply-management');
              }, loadErrorCallBack, 'supplier-contract-apply-management');
              break;
             //供应商合同管理
            case 'supplier-contract-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('supplier-contract-management');
                loadScope(scope, '#supplier-contract-management');
              }, loadErrorCallBack, 'supplier-contract-management');
              break;
            //合同管理
            case 'contract-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('contract-management');
                loadScope(scope, '#contract-management');
              }, loadErrorCallBack, 'contract-management');
              break;
            //合同申请
            case 'contract-apply-new':
              require.ensure([], function (require, exports, module) {
                var scope = require('contract-apply-new');
                loadScope(scope, '#contract-apply-new');
              }, loadErrorCallBack, 'contract-apply-new');
              break;
            //合同管理详情
            case 'contract-detail':
              require.ensure([], function (require, exports, module) {
                var scope = require('contract-detail');
                loadScope(scope, '#contract-detail');
              }, loadErrorCallBack, 'contract-detail');
              break;
            //合同免签审核
            case 'exempt-audit':
              require.ensure([], function (require, exports, module) {
                var scope = require('exempt-audit');
                loadScope(scope, '#exempt-audit');
              }, loadErrorCallBack, 'exempt-audit');
              break;
            // 专线订单管理
            case 'special-line-order':
              require.ensure([], function (require, exports, module) {
                var scope = require('special-line-order');
                loadScope(scope, '#special-line-order');
              }, loadErrorCallBack, 'special-line-order');
              break;
            // 专线订单详情
            case 'special-line-order-detail':
              require.ensure([], function (require, exports, module) {
                var scope = require('special-line-order-detail');
                loadScope(scope, '#special-line-order-detail');
              }, loadErrorCallBack, 'special-line-order-detail');
              break;
            // 公共专线管理
            case 'public-dedicated-line':
              require.ensure([], function (require, exports, module) {
                var scope = require('public-dedicated-line');
                loadScope(scope, '#public-dedicated-line');
              }, loadErrorCallBack, 'public-dedicated-line');
              break;
            // 预派单待确认
            case 'pre-dispatch-tobe-confirm':
              require.ensure([], function (require, exports, module) {
                var scope = require('pre-dispatch-tobe-confirm');
                loadScope(scope, '#pre-dispatch-tobe-confirm');
              }, loadErrorCallBack, 'pre-dispatch-tobe-confirm');
              break;
            // 以提代驳
            case 'pickup-barge':
              require.ensure([], function (require, exports, module) {
                var scope = require('pickup-barge');
                loadScope(scope, '#pickup-barge');
              }, loadErrorCallBack, 'pickup-barge');
              break;
            // 车辆运力预测详情
            case 'available-trans-capacity-vehicle':
              require.ensure([], function (require, exports, module) {
                var scope = require('available-trans-capacity-vehicle');
                loadScope(scope, '#available-trans-capacity-vehicle');
              }, loadErrorCallBack, 'available-trans-capacity-vehicle');
              break;
            // 可用运力预测
            case 'available-trans-capacity':
              require.ensure([], function (require, exports, module) {
                var scope = require('available-trans-capacity');
                loadScope(scope, '#available-trans-capacity');
              }, loadErrorCallBack, 'available-trans-capacity');
              break;
            //延保申请
            case 'extension-insurance-application':
              require.ensure([], function (require, exports, module) {
                var scope = require('extension-insurance-application');
                loadScope(scope, '#extension-insurance-application');
              }, loadErrorCallBack, 'extension-insurance-application');
              break;
            //延保申请记录
            case 'extension-insurance-application-list':
              require.ensure([], function (require, exports, module) {
                var scope = require('extension-insurance-application-list');
                loadScope(scope, '#extension-insurance-application-list');
              }, loadErrorCallBack, 'extension-insurance-application-list');
              break;
            //延保申请详情
            case 'extension-insurance-examine':
              require.ensure([], function (require, exports, module) {
                var scope = require('extension-insurance-examine');
                loadScope(scope, '#extension-insurance-examine');
              }, loadErrorCallBack, 'extension-insurance-examine');
              break;
            //服务管理
            case 'service-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('service-management');
                loadScope(scope, '#service-management');
              }, loadErrorCallBack, 'service-management');
              break;
            //合同版本管理
            case 'contract-version-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('contract-version-management');
                loadScope(scope, '#contract-version-management');
              }, loadErrorCallBack, 'contract-version-management');
              break;
            //服务主体
            case 'service-main':
              require.ensure([], function (require, exports, module) {
                var scope = require('service-main');
                loadScope(scope, '#service-main');
              }, loadErrorCallBack, 'service-main');
              break;
              //服务主体详情
            case 'service-detail':
              require.ensure([], function (require, exports, module) {
                var scope = require('service-detail');
                loadScope(scope, '#service-detail');
              }, loadErrorCallBack, 'service-detail');
              break;
              //部门签约主体管理
            case 'department-contract-main-management':
              require.ensure([], function (require, exports, module) {
                var scope = require('department-contract-main-management');
                loadScope(scope, '#department-contract-main-management');
              }, loadErrorCallBack, 'department-contract-main-management');
              break;
               //合同签署管理
               case 'contract-signing':
                require.ensure([], function (require, exports, module) {
                  var scope = require('contract-signing');
                  loadScope(scope, '#contract-signing');
                }, loadErrorCallBack, 'contract-signing');
                break;
              // 小箱拼车地图（旧）
              case "lcl-map":
                require.ensure(
                  [],
                  function (require, exports, module) {
                    var scope = require("lcl-map");
                    loadScope(scope, "#lcl-map");
                  },
                  loadErrorCallBack,
                  "lcl-map"
                );
                break;
              // 小箱拼车
              case "twinhaul-map":
                require.ensure(
                  [],
                  function (require, exports, module) {
                    var scope = require("twinhaul-map");
                    loadScope(scope, "#twinhaul-map");
                  },
                  loadErrorCallBack,
                  "twinhaul-map"
                );
                break;
              // 供应商签署管理
              case 'supplier-contract-signing':
                require.ensure([], function (require, exports, module) {
                  var scope = require('supplier-contract-signing');
                  loadScope(scope, '#supplier-contract-signing');
                }, loadErrorCallBack, 'supplier-contract-signing');
                break;
              // 海运订舱待客户确认价格
              case 'sea-freight-booking-customer-confirm-price':
                require.ensure([], function (require, exports, module) {
                  var scope = require('sea-freight-booking-customer-confirm-price');
                  loadScope(scope, '#sea-freight-booking-customer-confirm-price');
                }, loadErrorCallBack, 'sea-freight-booking-customer-confirm-price');
                break;
              // 海运订舱待确认舱位
              case 'sea-freight-booking-confirm-shipping-space':
                require.ensure([], function (require, exports, module) {
                  var scope = require('sea-freight-booking-confirm-shipping-space');
                  loadScope(scope, '#sea-freight-booking-confirm-shipping-space');
                }, loadErrorCallBack, 'sea-freight-booking-confirm-shipping-space');
                break;
              // 海运订舱待上传托书
              case 'sea-freight-booking-upload-consignment-note':
                require.ensure([], function (require, exports, module) {
                  var scope = require('sea-freight-booking-upload-consignment-note');
                  loadScope(scope, '#sea-freight-booking-upload-consignment-note');
                }, loadErrorCallBack, 'sea-freight-booking-upload-consignment-note');
                break;
              // 客户群机器人管理
              case 'customer-group-robot-management':
                require.ensure([], function (require, exports, module) {
                  var scope = require('customer-group-robot-management');
                  loadScope(scope, '#customer-group-robot-management');
                }, loadErrorCallBack, 'customer-group-robot-management');
                break;
              // 拉机器人入群
              case 'add-robots-to-group':
                require.ensure([], function (require, exports, module) {
                  var scope = require('add-robots-to-group');
                  loadScope(scope, '#add-robots-to-group');
                }, loadErrorCallBack, 'add-robots-to-group');
                break;
              // 绑定客户群
              case 'bind-customer-group':
                require.ensure([], function (require, exports, module) {
                  var scope = require('bind-customer-group');
                  loadScope(scope, '#bind-customer-group');
                }, loadErrorCallBack, 'customer-group-robot-management');
                break;
              //合同签署详情
              case 'contract-signing-detail':
                require.ensure([], function (require, exports, module) {
                  var scope = require('contract-signing-detail');
                  loadScope(scope, '#contract-signing-detail');
                }, loadErrorCallBack, 'contract-signing-detail');
                break;
              //批量压缩
              case 'contract-batch-archive':
                require.ensure([], function (require, exports, module) {
                  var scope = require('contract-batch-archive');
                  loadScope(scope, '#contract-batch-archive');
                }, loadErrorCallBack, 'contract-batch-archive');
              break;
              //新工厂登记待审批
              case 'factory-addr-register-audit':
                require.ensure([], function (require, exports, module) {
                  var scope = require('factory-addr-register-audit');
                  loadScope(scope, '#factory-addr-register-audit');
                }, loadErrorCallBack, 'factory-addr-register-audit');
              break;
              //展会管理(新)
              case 'exhibition-manage':
                require.ensure([], function (require, exports, module) {
                  var scope = require('exhibition-manage');
                  loadScope(scope, '#exhibition-manage');
                }, loadErrorCallBack, 'exhibition-manage');
              break;
              //展会客户登记
              case 'exhibition-customer-registration':
                require.ensure([], function (require, exports, module) {
                  var scope = require('exhibition-customer-registration');
                  loadScope(scope, '#exhibition-customer-registration');
                }, loadErrorCallBack, 'exhibition-customer-registration');
              break;
              //新能源采集
              case 'charging-resource-collection':
                require.ensure([], function (require, exports, module) {
                  var scope = require('charging-resource-collection');
                  loadScope(scope, '#charging-resource-collection');
                }, loadErrorCallBack, 'charging-resource-collection');
              break;
              // 取消无纸化
              case 'cancel-paperless':
                require.ensure([], function (require, exports, module) {
                  var scope = require('cancel-paperless');
                  loadScope(scope, '#cancel-paperless');
                }, loadErrorCallBack, 'cancel-paperless');
              break;
              // 派发无纸化
              case 'distribute-paperless':
                require.ensure([], function (require, exports, module) {
                  var scope = require('distribute-paperless');
                  loadScope(scope, '#distribute-paperless');
                }, loadErrorCallBack, 'distribute-paperless');
              break;

            }
            
            _.ui.jsGridTableBar({
              table: $('.jsgrid-grid-body:visible')
            })

            $(document.body).scrollTop(0).trigger('scroll') //init scroll
          })
            }
          });
        }
      }
    },
    path: function (href) {
      var id = href + '-' + (Math.random() * 10000).toFixed(0)
      var isSingle = window.esapp.pages[href].isSingle;
      if (window.esapp.pages[href].isSingle) {
        this.watch(href);
      }

      window.esapp.pages[href].current = true;
      var hash = isSingle ? href : href + '/' + id;
      location.hash = hash;
    },
    watch: function (href) {
      if (href == 'to-shop-client') {
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          window.open("http://shop.fat.driverglobe.com",'shop')
          return;
        }
        window.open("https://shop.carrierglobe.com",'shop')
        return;
      }
      if (href == 'to-vehicle-management') { 
        if (location.href.indexOf('local') >= 0) {
          const toUrl = 'http://localhost:8080/#/?from=F2E'
          handleF2eToOther(toUrl,'vehicle')
          return;
        }
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          const toUrl = 'http://vehicle.fat.driverglobe.com/new#/?from=F2E'
          handleF2eToOther(toUrl,'vehicle')
          return;
        }
        const toUrl = 'https://vehicle.carrierglobe.com/new#/?from=F2E'
        handleF2eToOther(toUrl,'vehicle')
        return;
      }

      if (href == 'to-vehicle-sales-management') {
        // console.log('to-vehicle-sales-management','to-vehicle-sales-management')
        if (location.href.indexOf('local') >= 0) {
          const toUrl = 'http://localhost:8080/#/?from=F2E'
          handleF2eToOther(toUrl,'vehicle')
          return;
        }
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          const toUrl = 'https://vehicle.fat.driverglobe.com/#/login?from=F2E'
          handleF2eToOther(toUrl,'vehicle')
          return;
        }
        const toUrl = 'https://vehicle.carrierglobe.com/#/login?from=F2E'
        handleF2eToOther(toUrl,'vehicle')
        return;
      }

      if (href == 'to-ttp-web') {
        if (location.href.indexOf('local') >= 0) {
          const toUrl = 'http://localhost:3000/login?from=F2E'
          handleF2eToOther(toUrl,'ttp')
          return;
        }
        if (location.href.indexOf('fat') >= 0) {
          const toUrl = 'https://ttp.fat.driverglobe.com/login?from=F2E'
          handleF2eToOther(toUrl,'ttp')
          return;
        }
        const toUrl = 'https://ttp.carrierglobe.com/login?from=F2E'
        handleF2eToOther(toUrl,'ttp')
        return;
      }
      if (href == 'product-price') {
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          window.open(`http://new.fat.driverglobe.com/subsystem/ppc?default=${true}`,'productPrice')
          return;
        }
        window.open(`https://new.carrierglobe.com/subsystem/ppc?default=${true}`,'productPrice') //  https://new.carrierglobe.com/subsystem/ppc/
        // window.open("http://localhost:8080/insurance-company");
        return;
      }

      if (href == 'to-dispatch-analysis') {
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          window.open("https://new.carrierglobe.com/analysis/",'dispatchAnalysis');
          return;
        }
        window.open("https://new.carrierglobe.com/analysis/",'dispatchAnalysis');
        // window.open("http://local.fat.driverglobe.com:8080/new");
        // return;
      }

      if (href == 'driver-car-system') {
        const payload = { loginToken:window.localStorage.getItem('login-token')}
        if (location.href.indexOf('local') >= 0) {
          const toUrl = 'http://localhost:8080/#/?from=F2E&redirect=/carrier-verify'
          handleF2eToOther(toUrl,'ftboss',500,payload)
          return;
        }
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          const toUrl = 'http://ftboss.fat.driverglobe.com/#/?from=F2E&redirect=/carrier-verify'
          handleF2eToOther(toUrl,'ftboss',500,payload)
          return;
        }
        const toUrl = "https://ftboss.carrierglobe.com/#/?from=F2E&redirect=/carrier-verify"
        handleF2eToOther(toUrl,'ftboss',500,payload)
        return;
      }

      if (href == 'ds-monitor') {
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          handleF2eToOther('https://monitor.fat.driverglobe.com/#/?from=F2E','ds-monitor')
          return;
        }
        handleF2eToOther("https://monitor.carrierglobe.com/#/?from=F2E", "ds-monitor")
        return;
      }
      if (href == 'lcl-system') {
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          window.open("http://ltl.fat.driverglobe.com",'lclSystem')
          return;
        }
        window.open("https://ltl.carrierglobe.com",'lclSystem')

        return;
      }

      // 新的事故理赔外链
      if (href == 'to-security-management') {
        if (location.href.indexOf('local') >= 0) {
          const toUrl = 'http://localhost:8080/#/?from=F2E'
          handleF2eToOther(toUrl,'security-management')
          return;
        }
        if (location.href.indexOf('fat') >= 0 || location.href.indexOf('uat') >= 0) {
          const toUrl = 'https://security.fat.driverglobe.com/#/?from=F2E'
          handleF2eToOther(toUrl,'security-management')
          return;
        }
        const toUrl = 'https://security.carrierglobe.com/#/?from=F2E'
        handleF2eToOther(toUrl,'security-management')
        return;
      }


      if (window.esapp.menu)
        window.esapp.menu.highlight('#' + href)
      this.change(href, {
        single: esapp.pages[href] ? esapp.pages[href].isSingle : false
      }, {
        id: href
      })
      console.log(href,'--->',window.scopeList);
      if (window.scopeList && window.scopeList['control-center'] ) {
        window.scopeList['control-center'].vm.$eventBus.$emit('hideGlobalDriverInfoModal')
      }
    
    },
    init: function () {
      this.forEachCB = function (n, key) {
        var path = n.isSingle ? key : key + "/:id";
        Path.map("#" + path).to(function () {
          var href = n.isSingle ? key : key + '/' + this.params['id'];
          esapp.route.watch(href);
        });
      };
      _.forEach(esapp.pages, this.forEachCB);
    },
    refresh: function () {
      if (window.esapp.pages) {
        _.forEach(window.esapp.pages, function (p, key) {
          if (key == window.esapp.route.current) {
            var href = p.isSingle ? key : key + '/' + this.params['id'];

            $('[data-value=' + href + '] .shell-tab-remove').trigger('click')
            esapp.route.change(href, {
              single: esapp.pages[href] ? esapp.pages[href].isSingle : false
            }, {
              id: href
            })
          }
        });
      }
    }
  }
  window.esapp.base = {
    lang: 'lang'
  };
  const { iamHttp } = require('../api/_http')
  var permissionList = []
  // 格式化权限列表
  function configTree (list) {
      for(let i in list) {
          if (list[i].query) permissionList.push(list[i].query)
          if (list[i].children && list[i].children.length > 0 ) {
              configTree(list[i].children)
          }
      }
  }
  function getMenuList () {
      iamHttp({
        url: '/iam-auth-web/auth/user/user/menu/list',
        method: 'get',
        params: {}
      }).then(res => {
        if (!res.data.success) {
            _.ui.notify({
                message: '更新权限失败:' + res.data.errMessage,
                type: 'warning'
            });
            setTimeout(() => {
              _.util.forwardredirect('../login.html')
            },1000)
        } else {
            if(res.data.data.length === 0) {
                _.ui.notify({
                    message: '请联系管理员开通权限！',
                    type: 'warning'
                });
                setTimeout(() => {
                  _.util.forwardredirect('../login.html')
                },1000)
                return
            }
            configTree(res.data.data)
            localStorage.setItem('permissionList',JSON.stringify(permissionList))
        }
      })
  }
//   const watermark = require('./watermark.js')
const watermark = require('./watermarks.js')
  function waterMark () {
    function formatDate(date) {
        var date = new Date(date);
        var YY = date.getFullYear() ;
        var MM =
        (date.getMonth() + 1 < 10
         ? "0" + (date.getMonth() + 1)
         : date.getMonth() + 1);
        var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
        var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
        var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return YY +'-'+ MM + '-' + DD + '       ' + hh +':' + mm;
    }   
    if(localStorage.getItem('iam-user-info')) {
        const info = JSON.parse(localStorage.getItem('iam-user-info'))
        // watermark.load({ watermark_txt: `${info.nick}${info.memberNo} ${formatDate(new Date())}`})   
        // watermark.load({ watermark_txt: "测试水印，1021002301，测试水印，100101010111101"})
        watermark.watermark({watermark_txt:`${info.nick}${info.memberNo}<br />${formatDate(new Date())}`,watermark_y_space: 120,watermark_alpha: 0.08,watermark_x:20,watermark_y:75})     
    }
  }
  window.esapp.init = function () {
    require.ensure([], function () {
      window.esapp.route.init();
      //  通过刷新页面更新权限，不需要再退出登录  
      if (localStorage.getItem('iam-token')) {
          getMenuList()
      }
      waterMark()
      $(document.body).delegate('#logout', 'click', function () {
        _.util.ajax_get("/user/logout", {}, function (res) {
          if (res.success) {
            const tepCommonWords = window.localStorage.getItem('commonWords')
            localStorage.clear()
            localStorage.setItem('commonWords',tepCommonWords)   //保留司机投诉常用语
            _.util.forwardredirect('../login.html')
          }
        })
      })
      $('#shell-tabs').delegate('a', 'click', function (e) {
        if ($(this).data('value') == 'control-center') {
          if (window.scopeList && window.scopeList[$(this).data('value')]) {
            if(window.scopeList[$(this).data('value')].vm.$children && window.scopeList[$(this).data('value')].vm.$children.length > 0) {
              if(window.scopeList[$(this).data('value')].vm.$children[0].hasOwnProperty('setCustomerList')) { // 客服修改对应的客服组关系时更新数据
                window.scopeList[$(this).data('value')].vm.$children[0].setCustomerList()
              }
            }
          }
        }
        location.hash = $(this).data('value');
        e.preventDefault()
      })
      // shell tabs remove event
      $('#shell-tabs').delegate('.shell-tab-remove', 'click', function (e) {
        e.preventDefault()
        var id = $(this).data('value')
        $('#shell-tabs a[href="#' + id + '"]').remove()
        $('#' + id).remove()
        $('.input-drop.input-filter-drop').hide()
        location.hash = $('#shell-tabs a:last').data('value')

        // 删除 extra-service-client 项目内的 hashchange 监听
        window.removeEventListener('hashchange', window[`onHashchange-${e.currentTarget.getAttribute("data-value")}`])
        // 删除页面的时候销毁页面实例
        if (window.scopeList && window.scopeList[id]) {
          window.scopeList[id].vm.$eventBus.$emit('pageDestroy')
          window.scopeList[id].vm.$destroy()
        }
        return false
      })

      Path.map("#target/_blank/:id").to(function () {
        esapp.route.change(this.params['id'], {
          single: false,
          target: '_blank'
        }, {
          id: 1
        })
      });
      $(document).keydown(function (event) {
        if (event.target.className != 'w-e-text' && event.target.nodeName != "INPUT" && event.target.nodeName != "TEXTAREA" && event.keyCode == 8 && !event.target.classList.contains('wujie_iframe')) {
          event.preventDefault()
        }
      });
      $('body').on("click", '[esapp-href]', function (e) {
        var href = $(this).attr("esapp-href");
        // if (href) {
        esapp.route.path(href)
        // }
      })

      $("body").delegate(".sidebar-toggler", "click", function (a) {
        var e = $("body"),
          i = $(".page-sidebar-menu");
        e.hasClass("page-sidebar-closed") ? (e.removeClass("page-sidebar-closed"), i.removeClass("page-sidebar-menu-closed")) : (e.addClass("page-sidebar-closed"), i.addClass("page-sidebar-menu-closed"), e.hasClass("page-sidebar-fixed") && i.trigger("mouseleave"))
      })
      if (window.location.href.indexOf('container-order.html') >= 0) {
        return;
      }
      _.util.ajax_get('/user/current', {}, function (res) {
        // 判断登陆方式
        console.log(localStorage.getItem('loginMethod'))
        var loginMethod = localStorage.getItem('loginMethod')
        if (res.success) {
          _.util.ajax_get('/duckBill/createQRCode',{key: '11111',qrType:'QR_LIMIT',bdUserId:res.user.userName}, function (resq) {
            if (resq) {
              var ticket = JSON.parse(resq.data.value).ticket
              $('#qrcode').attr('href',`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${
                encodeURI(ticket)}`)
            }
          });
          // 注释代码
          // fetchStrategy().then(res => {
          //   window.__ACCEPT_GROUP_STRATEGY__ = res.data.data
          // }).catch(console.log)

          // 调试代码
          // postingDispatchGroupDutyStrategyImpl 职责
          // postingDispatchGroupRouteStrategyImpl 路线

          // fetchStrategy().then(res => {
          //   window.__ACCEPT_GROUP_STRATEGY__ = 'postingDispatchGroupDutyStrategyImpl'
          // }).catch(err => {
          //   window.__ACCEPT_GROUP_STRATEGY__ = 'postingDispatchGroupDutyStrategyImpl'
          // })
          // fetchStrategy().then(res => {
          //   window.__ACCEPT_GROUP_STRATEGY__ = 'postingDispatchGroupRouteStrategyImpl'
          // }).catch(err => {
          //   window.__ACCEPT_GROUP_STRATEGY__ = 'postingDispatchGroupRouteStrategyImpl'
          // })
          // dkAcceptGroup.default.methods.fetchData("INTERNAL_POSTING").catch(console.log)
          // dkAcceptGroup.default.methods.fetchData("DRIVER_POSTING").catch(console.log)
          var menuItem = window.esapp.menuMap.menuList
          var parentItem = window.esapp.menuMap.parentList
          window.esapp.rolesList = res.user.roles
          // 权限列表从用户中心过来，替换接口的权限列表   
          if (loginMethod === 'iam-login') {
              res.user.authorities = JSON.parse(localStorage.getItem('permissionList'))
          } else if (loginMethod === 'f2e-login' && res.user.authorities.length === 0) { //走老的接口权限
            _.ui.notify({
                message: '暂无权限',
                type: 'warning'
            });
            setTimeout(() => {
              _.util.forwardredirect('../login.html')
            },1000)
          }
          try {
            window.localStorage.setItem('webphone', JSON.stringify(res.user))
          } catch (e) {
            if (e.name == 'QuotaExceededError') {
              // localStorage.clear();
              localStorage.removeItem('webphone')
              window.localStorage.setItem('webphone', JSON.stringify(res.user))
            }
          }
          var menuItems = [];
          _.forEach(menuItem, function (value) {
            if(loginMethod === 'iam-login'){
                if (_.findIndex(res.user.authorities, function (o) {
                    return o == value.auth;
                  }) > -1) {
                  var item = _.find(parentItem, ['name', value.parent])
                  item && item.subMenu.push(value)
                }
            } else {
                if (_.findIndex(res.user.authorities, function (o) {
                    let str = ''
                    if (value.auth && value.auth.indexOf('-M-')>-1) {
                        str = value.auth.substr(0,value.auth.indexOf('-M-'))
                    } else if (value.auth && value.auth.indexOf('-M')>-1 && value.auth[value.auth.length - 1] === 'M') {
                        str = value.auth.substr(0,value.auth.indexOf('-M'))
                    } else {
                        str = value.auth
                    }
                    return o == str;
                  }) > -1) {
                  var item = _.find(parentItem, ['name', value.parent])
                  item && item.subMenu.push(value)
                }
            }
          });
          _.forEach(parentItem, function (value) {
            if (value.subMenu.length) {
              menuItems.push(value);
            }
            value.subMenu.sort(function (a, b) {
              var aseq = a.seq || 999,
                bseq = b.seq || 999;
              if (aseq > bseq) {
                return 1;
              } else if (aseq < bseq) {
                return -1;
              } else {
                return 0;
              }
            })
          });
          window.esapp.route.isHomepage = location.hash;
          window.esapp.authorities = res.user.authorities
          location.hash = ''

          window.esapp.menu = window.esWidget.esMenu({
            currentPage: location.hash,
            menuList: {
              items: menuItems
            },
            showCallBack: _.util.showOrderNumber
          })
          $('.userName').text(res.user.userDisplayName);
          window.esapp.user = res.user;

          $('#iframe').css('visibility', 'visible')
          $('#shell_menu').css('visibility', 'visible')
          if (self != top) {
            $('.page-container').css('margin', '0px')
            $('.page-content').css('padding', '0px')
          }
        } else {
          if (location.hash.indexOf('car-position') >= 0) {
            window.esapp.route.isHomepage = location.hash;
            window.esapp.menu = window.esWidget.esMenu({
              currentPage: '',
              menuList: {
                items: []
              },
              showCallBack: _.util.showOrderNumber
            })
            $('#iframe').css('visibility', 'visible')
            $('#shell_menu').css('visibility', 'visible')
          } else {
            // _.ui.notify({
            //     message: '用户信息获取失败',
            //     type: 'warning'
            // });
            // setTimeout(() => {
            //   _.util.forwardredirect('../login.html')
            // },1000)
            if (process.env.NODE_ENV === 'development') {
                // 解决本地启动时，错误cookie导致多次登陆失败
                document.cookie = "SESSION=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=.fat.driverglobe.com"
             }
             _.util.forwardredirect('../login.html')
          }
        }
      })
    })
  }

  $(function () {
    require(['map'], function (data) {
      window.esapp.menuMap = data
      window.esapp.pages = {}
      data.menuList.forEach(function (v) {
        window.esapp.pages[v.href] = v
      })
      window.esapp.init()
    })

    $(document).on("click", function (c) {
      var b = $(c.srcElement || c.target);
      $("td.brief").find("div.action-dropdown").removeClass("current");
      b.parents("td.brief").find("div.action-dropdown").addClass("current");
      $(".input-drop").each(function () {
        if ($(this).is(":visible") && !$(b).is(".input-drop *")) {
          var f, d;
          if ($("#" + $(this).data("value")).val() && $(this).children("div.input-port-item-selected").length > 0) {
            if (!$("#" + $(this).data("value")).hasClass("no-auto-select")) {
              f = $(this).children("div.input-port-item-selected").eq(0);
              if (f.length > 0) {
                $("#" + $(this).data("value")).val("").data("value", "");
                $("#" + $(this).data("value")).siblings(".uneditable-label").html("");
                f.trigger("click")
              }
            }
          } else {
            if ($("#" + $(this).data("value")).val()) {
              f = $(this).children("div").eq(0);
              d = $("#" + $(this).data("value"));
              if (!d.data("value")) {
                if (f.length > 0 && d.hasClass("needEmpty")) {
                  d.val("");
                  d.siblings(".uneditable-label").html("")
                }
              }
            }
          }
          $(this).hide();
          var j = $(this).data().value;
          if ($("#" + j).hasClass("trigger")) {
            $(this).show();
            $("#" + j).removeClass("trigger")
          }
          if ($("#" + j).parent("span").hasClass("input") && !b.hasClass("input")) {
            if (!b.parents("td").hasClass("input") && !$("#" + j).parents("tr").hasClass("edit")) {
              var e = $("#" + j),
                g = $("#" + j).val(),
                h = $("#" + j).data("value") || "";
              e.parents("td.input").find("span").removeClass("active");
              e.parents("td.input").find("span.view").addClass("active").html(g);
              e.parents("td.input").find("span.view").data("value", h);
              $(window).resize()
            }
          }
          if ($("#" + j).parent("div").hasClass("edit") && !b.parent("div").hasClass("edit") && !b.hasClass("edit")) {
            var g = $("#" + j).val(),
              h = $("#" + j).data().value || "";
            $("#" + j).hide();
            $("#" + j).parent("div").find("i.edit-icon").show();
            $("#" + j).parent("div").find("span.view").html(g).data("value", h).show()
          }
        }
      });
    });
  })
})()
