require('../js/core.js');
require('../js/index-websocket.js');
require('../fonts/iconfont.css')
require('../css/font-awesome.css')
require('../css/components.min.css')
require('../css/default.min.css')
require('../css/layout.min.css')
require('../css/harbour.layout.css')
require('../css/shell.css')
require('../css/viewer.min.css')

require('./vue')
require('babel-polyfill')

const loadExternalService = require("../js/loadExternalService");
loadExternalService.loadExternalCommonChunk().catch(err => {
  console.error(err, "extra common chunk 加载错误")
})
