
<template>
    <div style="display:inline-block;margin-left: 5px;">
        <span style="color:#337ab7;cursor: pointer;" v-if="value" @click="phoneClick" class="fa fa-phone"></span>
    </div>
</template>

<script>
export default {
    name: "dk-icon-phone",
    data () {
        return {
            isFats: false
        };
    },
    props: {
        value: {
            type: null,
            default: ""
        }
    },
    mounted () {
        if (window.location.origin.indexOf('fat') !== -1 || window.location.origin.indexOf('local') !== -1) {
            this.isFats = true
        } else if (window.location.origin.indexOf('carrierglobe') !== -1) {
            this.isFats = false
        }
    },
    methods: {
        phoneClick () {
            if (!this.value) {
                return;
            }
            if(this.isFats) {
                window.open(`callcentertest://?phone=${this.value}`, "_blank");
            } else {
                window.open(`callcenter://?phone=${this.value}`, "_blank");
            }
        }
    }
};
</script>