<template>
  <div class="video-content">
    <div v-if="isPlay" class="video-content-video">
      <img class="video-content-img" :src="videoData.poster" alt="视频" />
      <video :src="videoData.url" controls preload="auto" autoplay style="width: 100%;"></video>
    </div>
    <div v-else class="video-content-poster" @click="onPlayVideo">
      <img class="video-content-img" :src="videoData.poster" alt="视频" />
      <div class="video-content-iocn">
      <i class="el-icon-video-play"></i>
      </div>
      <span class="video-content-duration">{{ videoData.duration | mmss }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "dk-video-box",
  props: {
    videoInfo: {
      type: Object | String,
      default: {},
    },
  },
  data() {
    return {
      isPlay: false,
      videoData: {
        url: "",
        poster: "",
        duration: 0,
      },
    }
  },
  filters: {
    mmss(timestamp) {
      timestamp = Math.floor(timestamp)
      const hour = Math.floor(timestamp / 3600);
      const minute = Math.floor((timestamp % 3600) / 60);
      const second = timestamp % 60;
      
      if (!hour) {
        return `${minute < 10 ? "0" + minute : minute}:${second < 10 ? "0" + second : second}`;
      }

      return `${hour}:${minute < 10 ? "0" + minute : minute}:${second < 10 ? "0" + second : second}`;
    }
  },
  mounted() {
    if (typeof this.videoInfo === 'string') {
      this.videoData = JSON.parse(this.videoInfo)
    } else {
      this.videoData = this.videoInfo
    }
  },
  methods:{
    onPlayVideo() {
      this.isPlay = true
    },
  }
};
</script>
<style scoped>
.video-content {
  width: 140px;
}

.video-content-video {
  position: relative;
  width: 100%;
}
.video-content-video .video-content-img {
  width: 100%;
  border-radius: 6px;
  opacity: 0;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.video-content-poster {
  position: relative;
  width: 100%;
}

.video-content-poster .video-content-img {
  width: 100%;
  border-radius: 6px;
}
.video-content-iocn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: #fff;
}
.video-content-duration {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
}
</style>