<template>
  <div>
    <!-- eir -->
    <dk-modal
      :title="eirShow.title"
      :style-objs="eirShow.style"
      @change="eirShow.fade= false;"
      :fade="eirShow.fade"
    >
      <div class="modal-body changeBill" style="min-height: 500px;padding-top:0;">
        <!-- eir信息 -->
        <div style="position:absolute;left:0;top:16px;width:900px;">
          <!-- start -->
          <div class="container eir" style="width:900px;">
            <div class="eir-detail-hd clearfix">
              <!-- <button type="button" class="btn btn-primary" @click="showDetialBang(0,1)">刷新</button>
              <span v-if="eirDetail.originalData.lastUpdateTime">最新刷新时间：{{eirDetail.originalData.lastUpdateTime}}</span> -->
              <div class style="line-height:20px;color:#0089e9;">发单人：{{eirDetail.carrierName}}</div>
              <div style="line-height:20px;color:#0089e9;">
                  营运人：{{eirDetail.trustCode}} 
                  <span class="tag-label" v-if="eirDetail.originalData && eirDetail.originalData.socFlag == 'Y'">SOC</span>
                  <span class="tag-label" style="cursor: pointer;" v-if="eirDetail.originalData && eirDetail.originalData.repcntrFlag && eirDetail.originalData.repcntrFlag != 0">
                        <el-popover trigger="hover" placement="right" width="250">
                            <span v-if="eirDetail.relationEirNo">	
								新：编号{{eirDetail.relationEirNo}}
							</span>
                            <span slot="reference">
								换箱
							</span>
                        </el-popover>
                  </span>
              </div>
              <div class style="line-height:20px;color:#0089e9;">EIR编号：{{eirDetail.eirNo}}
                <span style="margin-left:15px;" v-if="eirDetail.originalData && eirDetail.originalData.pickupName">堆场：{{eirDetail.originalData.pickupName}}</span>
              </div>
              <div style="clear: both;"></div>
            </div>
            <table
              width="100%"
              cellpadding="0"
              cellspacing="0"
              border="0"
              class="eir-table-view fot-color"
            >
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <tbody>
                <tr>
                  <th colspan="2" style="padding-left:17px;">用箱人／运箱人（CONTAINER USER/HAULIER）</th>
                  <th>提箱地点（PLACE OF DELIVERY）</th>
                  <th>预约号（RESERVATION NUMBER）</th>
                </tr>
                <tr>
                  <!--<td colspan="2">上海鸭嘴兽供应链管理有限公司</td>-->
                  <td colspan="2" v-if="eirDetail.originalData">{{eirDetail.originalData.cntrUserName}}</td>
                  <td colspan="2" v-else></td>
                  <td v-if="eirDetail.originalData">{{eirDetail.originalData.delivPlaceStr}}</td>
                  <td v-else></td>
                  <td>{{eirDetail.pickupReservedNo}}</td>
                </tr>
                <tr>
                  <th style="padding-left:17px;">提箱截止时间(DEADLINE TIME)</th>
                  <th>发往地点(DELIVERED TO)</th>
                  <th colspan="1">收箱地点(PLACE OF RETURN)</th>
                  <th>进港代码（CY-IN CODE）</th>
                </tr>
                <tr>
                  <td>{{eirDetail.pickupValidTime}}</td>
                  <td v-if="eirDetail.originalData">{{eirDetail.originalData.delivToStr}}</td>
                  <td v-else></td>
                  <td colspan="1" v-if="eirDetail.originalData">{{eirDetail.originalData.rtnPlaceStr}}</td>
                  <td colspan="1" v-else></td>
                  <td>{{eirDetail.dischargingPort}}</td>
                </tr>
                <tr>
                  <th style="padding-left:17px;">
                    船名／航次
                    <br />(VESSEL/VOYAGE NO.)
                  </th>
                  <th>
                    集装箱号
                    <br />(CONTAINER NO.)
                  </th>
                  <th>
                    尺寸类型
                    <br />(SIZE TYPE)
                  </th>
                  <th>
                    持箱人
                    <br />(CNTR OPTR.)
                  </th>
                </tr>
                <tr>
                  <td>{{eirDetail.vesselName}}/{{eirDetail.voyage}}</td>
                  <td>{{eirDetail.ctnNo}}</td>
                  <td>{{eirDetail.ctnTypeSize}}</td>
                  <td>{{eirDetail.ctnOwnerCode}}</td>
                </tr>
                <tr>
                  <th style="padding-left:17px;">
                    提单号
                    <br />(B/L NO.)
                  </th>
                  <th>
                    铅封号
                    <br />(SEAL NO.)
                  </th>
                  <th>
                    免费期限
                    <br />(FERR TIME PERIOD)
                  </th>
                  <th>
                    承运车辆牌号
                    <br />(TRUCK WANON BARGE NO.)
                  </th>
                </tr>
                <tr>
                  <td>{{eirDetail.blNoPrimary}}</td>
                  <td>{{eirDetail.sealNo}}</td>
                  <td v-if="eirDetail.originalData">{{eirDetail.originalData.freeTimePeriod}}</td>
                  <td v-else></td>
                  <td>{{eirDetail.truckPlateNumber}}</td>
                </tr>
              </tbody>
            </table>
            <table
              width="100%"
              cellpadding="0"
              cellspacing="0"
              border="0"
              class="eir-table-view fot-color"
            >
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <tbody>
                <tr>
                  <th style="border-top:0;padding-left:16px;">
                    出场目的／状态
                    <br />(PPS OF GATE-OUT/STATUS)
                  </th>
                  <th style="border-top:0;">
                    进场目的／状态
                    <br />(PPS OF GATE-IN/STATUS)
                  </th>
                  <th style="border-top:0;">
                    出场日期
                    <br />(TIME OUT)
                  </th>
                  <th style="border-top:0;">
                    进场日期
                    <br />(TIME IN)
                  </th>
                </tr>
                <tr>
                  <td v-if="eirDetail.originalData">{{eirDetail.originalData.outPpsStrCode}}/{{eirDetail.originalData.outPpsStatStrCode}}</td>
                  <td v-else></td>
                  <td v-if="eirDetail.originalData">{{eirDetail.originalData.inPpsStrCode}}/{{eirDetail.originalData.inPpsStatStrCode}}</td>
                  <td v-else></td>
                  <td>{{eirDetail.pickupTime}}</td>
                  <td>{{eirDetail.returnTime}}</td>
                </tr>
                <tr>
                  <th colspan="4">船东放箱备注</th>
                </tr>
                <tr>
                  <td colspan="4" style="white-space:pre-wrap">
                    <div class="shipper_memo">
                    {{eirDetail.remark}}
                    </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end -->
        </div>
        <!-- 跟踪信息 -->
        <!-- <div style="width: 100%;padding-left:600px;">
          <div class="dot-content">
            <div class="top-info">
              <div class="top-info-inner">
                EIR编号:
                <b>{{eirDetail.eirNo}}</b> / 运箱人:
                <b>{{eirDetail.originalData.haulierName}}</b>
              </div>
            </div>
            <div class="dot-container" id="dot" style="min-height:500px;">
              <ul>
                <li :class="index==0?'new-dot':''" v-for="(item,index) in eirLog">
                  <span class="tag-dot" v-show="index==0"></span>
                  <span class="dot"></span>
                  <div class="info">
                    <span>{{item.operDate}}</span>
                    <span>{{item.rcptStatusName}}（{{item.operName}} {{item.rcptConfUsername}}）</span>
                    <span>{{item.confCstName}}</span>
                    <span id="dot-system">系统:{{item.outSysName}}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-default" @click="eirShow.fade = false">取消</button>
      </div>
    </dk-modal>
  </div>
</template>
<script>
import dkModal from "dk-modal";
export default {
  name: "dk-eir",
//   props: ["id"],
  computed: {
    // newValue: {
    //   get: function() {
    //     return this.value;
    //   },
    //   set: function(value) {
    //     this.$emit("input", value);
    //   }
    // }
  },
  components: {
    "dk-modal": dkModal
  },
  data() {
    return {
      loading:false,
      eirShow: {
        fade: false,
        style: {
          width: "1000px",
        },
        title: "EIR信息"
      },
      eirLog: [],
      eirDetail: {
        originalData: {}
      },
      id: ''
    };
  },
  mounted() {},
  methods: {
    changeShow: function() {
        
      this.eirShow.fade = !this.eirShow.fade;
      console.log(this.eirShow.fade)
    },
    showDetialBang: function(id, refresh) {
      _.ui.mask()
      var that = this;
      id && (this.id = id)
      // _.util.ajax_get("/eir_spider/detail_and_log", { receiptNo: id || this.id , refresh: refresh ?true :false}, res => {
      //   if (res.success) {
      //     this.eirShow.fade = true
      //     var resValue = JSON.parse(res.data.value);
      //     var eirLog = resValue.eirLog;
      //     var eirDetail = resValue.eirDetail || {};
      //     console.log("eir", eirLog);
      //     that.eirLog = eirLog;
      //     that.eirDetail = eirDetail;
      //     that.eirDetail.lastUpdateTime = resValue.lastUpdateTime
      //   }
      //   that.loading=false;
      //   _.ui.unmask()
      // });
      _.util.ajax_get2("/dms-carrier-server/eir/query", { eirNos: id || this.id , extFlag: true }, res => {
        if (res.success) {
          this.eirShow.fade = true
          if (res.data.length > 0) {
            var resValue = res.data[0];
            resValue.originalData = JSON.parse(resValue.originalData)
            // var eirLog = resValue.eirLog;
            var eirDetail = resValue || {};
            // console.log("eir", eirLog);
            // that.eirLog = eirLog;
            that.eirDetail = eirDetail;
          }
        }
        that.loading=false;
        _.ui.unmask()
      });
    }
  }
};
</script>
<style>
/* eir */
.eir .eir-table-view {
  color: #666;
}
.eir .eir-table-view tr th {
  text-align: left;
  font-weight: normal;
  border: 1px solid #ccc;
  background-color: #fafafa;
}
.eir .eir-table-view tr th,
.eir .eir-table-view tr td {
  padding: 10px;
}
.eir .fot-color tr td {
  color: #0089e9;
  font-weight: bold;
  font-family: "microsoft yahei";
}
.eir .eir-table-view tr td {
  border: 1px solid #ccc;
}
.eir colgroup {
  display: table-column-group;
}
.eir .shipper_memo {
  border-radius: 4px;
  width: 95%;
  max-width: 530px;
  min-height: 15px;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  outline: 0;
  border: 1px solid #ccc;
  font-size: 12px;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
}
.eir table{
  table-layout:fixed;
  word-break:break-all;
}
.eir table td {
  word-wrap: break-word;
  white-space: pre-wrap  !important;
}
/* 时间轴 */
.big-layer-inner {
  width: 400px;
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 3px;
}
.top-info {
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 0;
  margin-bottom: 35px;
}
.top-info .top-info-inner {
  background-color: #f5f5f5;
  padding: 10px 20px;
  color: #666;
}
.dot-container {
  position: relative;
  border-left: 2px solid #ddd;
  margin-left: 50px;
}
.dot-container ul {
  padding-left: 20px;
  position: relative;
  top: -20px;
}
.dot-container ul li {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.dot-container ul li.new-dot span.tag-dot {
  width: 42px;
  height: 18px;
  position: absolute;
  left: -72px;
  top: 50%;
  margin-top: -11px;
  /* background: url(../static/imgs/ntag.gif) no-repeat; */
}

.dot-container ul li.new-dot span {
  color: #3eaf0e;
}
.dot-container ul li .info span {
  line-height: 25px;
}
.dot-container ul li span {
  display: block;
}
.dot-container ul li span.dot {
  position: absolute;
  left: -28px;
  top: 50%;
  margin-top: -11px;
  border-radius: 50%;
  background: #e3e3e3;
  width: 14px;
  height: 14px;
}
.dot-container ul li.new-dot .dot {
  border-radius: 50%;
  background: #3eaf0e;
  /* background: url(../static/imgs/ndot.gif) no-repeat; */
}
.dot-content{
    max-height: 800px;
    overflow-y: scroll;
}
.tag-label {
    display: inline-block;
    vertical-align: top;
    border: 1px solid orange;
    color: orange;
    padding: 0 7px;
    height: 16px;
    font-size: 12px;
    line-height: 14px;
    margin-left: 10px;
}
</style>


