const { http, genMeta, harbourHttp } = require('./_http')

/**
 * @desc 查询受理组列表
 * @param {{"showType": "DRIVER_POSTING", "portId": string}} payload
 */
exports.queryAcceptGroupList = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/list", {
    ...payload,
    meta: genMeta()
  })
}

/**
 * @desc 获取当前移交策略
 * @param {{strategyType: "POSTING_DISPATCH_GROUP" | ""}} payload
 * @returns {{ "postingDispatchGroupRouteStrategyImp" | "postingDispatchGroupDutyStrategyImpl" }}
 */
exports.fetchStrategy = (payload = {
  strategyType: "POSTING_DISPATCH_GROUP"
}) => {
  return http.get("/duckbill-workmsg/mng/strategy/query-strategy-name-by-type", {
    params: payload
  })
}

/**
 * @desc 获取问题列表
 */
exports.fetchQuestionTypeList = payload => {
  return http.post("/duckbill-workmsg/app/dispatch-group/query-all", payload)
}

/**
 * @desc 设置默认受理组
 * @param {{ id: string}} payload
 */
exports.setDefaultAcceptGroup = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/update-default-dispatch-group", payload)
}

/**
 * @desc 删除受理组
 * @param {{ id: string }} payload
 */
exports.deleteAcceptGroup = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/delete-by-parent-id", payload)
}
/**
 * @deprecated
 * @desc 获取受理组区域
 * @param {{ id: string }} payload
 */
exports.fetchDistrict = payload => {
  return http.post("/duckbill-workmsg/mng/district/selector", payload)
}

/**
 * @desc 获取受理组区域
 * @param {{ id: string }} payload
 */
exports.fetchDistrict = payload => {
  return http.post("/duckbill-workmsg/mng/district/selector", payload)
}
/**
 * @deprecated
 * @desc 根据港口和受理组id(可选)查询相同港口下除这个受理组id以外(可选)已经被选了的路线
 * @param {{ groupId?: string, portId: string}} payload
 */
exports.fetchDisabledDistrict = payload => {
  return http.get("/duckbill-workmsg/mng/dispatch-group/query-be-chose-groups-by-group-id-and-port-id", {
    params: payload
  })
}
/**
 * @desc 查询所有省市
 * @param {{ level: "city" }} payload
 */
exports.fetchAllDistrict = (payload = { level: "city" }) => {
  return http.post("/basic-data-server/district/search-all", {
    meta: genMeta({
      code: "BASIC-DATA"
    }),
    params: payload
  })
}

/**
 * @desc 根据受理组名称查询 对应的用户
 * @param {{ dispatcherGroup: string }} payload
 */
exports.fetchWokerList = (payload = { dispatcherGroup:"司机客服组"} ) => {
  return harbourHttp.post("/work-msg/user/queryUsersByWorkMsgUserDto", payload, {
    parseResp: true
  })
}

/**
 * @desc 获取当前移交策略
 */
 exports.userYazuishouSelector = (payload) => {
  return http.get("/../ws-truck/user/yazuishou/selector", {
    params: payload
  })
}

/**
 * @desc 新增或者修改受理组
 * @param {{ members:[], lines: [], dispatchGroupId: number, dispatcherGroupName: string}} payload 
 */
 exports.addOrUpdateGroup = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/add-or-update", payload)
}
/**
 * @desc 删除受理组之前检查是否当前成员将会无归属
 * @param {{ groupIds: string}} payload 
 */
 exports.checkMemberByGroupIds = payload => {
  return http.get("/duckbill-workmsg/mng/dispatch-group/query-not-exist-route-by-group-ids", {
    params: payload
  })
}

/**
 * @desc 获取 删除某港口下的线路
 * @param {{ portId: string}} payload 
 */
 exports.updateDisabledDispatchGroup = payload => {
  return http.post("/duckbill-workmsg/mng/dispatch-group/update-disabled-dispatch-group", {
    params: payload
  })
}
/**
 * @desc 获取 某港口下的默认组
 * @param {{ portId: string}} payload 
 */
 exports.queryPortDefaultGroup = payload => {
  return http.get("/duckbill-workmsg/mng/dispatch-group/query-default-group", {
    params: payload
  })
}
/**
 * @desc 获取某个组的操作日志
 * @param {{ groupId: string}} payload 
 */
 exports.fetchAcceptGroupOpLog = payload => {
  return http.get("/duckbill-workmsg/mng/dispatch-group/query-log", {
    params: payload
  })
}

/**
 * @desc 省市查询
 */
 exports.fetchProvinceAndCity = () => {
  return harbourHttp.get("/common/door-area/query-province-city", {
    parseResp: true
  })
}