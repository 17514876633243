<template>
  <div class="input-group" id="dk-accept-group-cascader">
    <span class="label-text">受理组:</span>
    <el-cascader
      :key="renderKey"
      popper-class="accept-group-cascader"
      ref="cascader"
      clearable
      :props="{ expandTrigger: 'hover', emitPath: false, checkStrictly: true }"
      size="small"
      :value="value"
      @input="onInput"
      :options="optionList"
      @change="chooseGroup"
      :emit-path="false"
    ></el-cascader>
  </div>
</template>

<script>
import { fetchDispatchGroupSelectOptions } from "../api/driverChat"

const map = {}
export default {
  props: {
    value: {
      default: "ALL",
      type: String
    },
    showType: {
      default: null,
      type: String
    },
    // portId for filter group
    filterValue: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      dispatcherGroupList: [],
      renderKey: 0,
      hasInit: false,
      renderFlag: false
    };
  },
  methods: {
    // pure
    fetchData(type) {
      if (map[type]) {
        return Promise.resolve(map[type])
      }
      // return fetchDispatchGroupSelectOptions({
      //   showType: type
      // }).then(res => {
      //   const data = _.get(res, 'data.data', [])

      //   const list = this.transformResult(data)
      //   list.unshift({
      //     value: 'ALL',
      //     label: "全部", // 必须指定非空值才会显示全部
      //   })
      //   map[type] = list
      //   return list
      // })
    },
    transformResult(list) {
      return list.map(v => {
        let value
        value = v.dispatchGroupId == null ? v.children.map(v => v.dispatchGroupId).join(",") : v.dispatchGroupId
        return {
          value,
          label: v.dispatchGroupName,
          id: v.dispatchGroupId,
          children: v.children ? this.transformResult(v.children) : null,
          portId: v.portId,
        }
      })
    },
    initGroupData() {
      if (map[this.showType]) {
        this.dispatcherGroupList = map[this.showType]
      } else {
        // this.fetchData(this.showType).then(list => {
        //   this.dispatcherGroupList = list
        // })
      }
    },
    chooseGroup(e) {
      // 目的是选择之后将下拉界面收起
      this.$refs.cascader.toggleDropDownVisible();
      console.log("change", e)
    },
    onInput(v) {
      console.log("input", v)
      this.$emit('input', v)
    },
    getDriverServiceValue() {
      const driverGroupList = this.dispatcherGroupList.find(v => v.label === "司机客服组")
      return driverGroupList ? driverGroupList.id : null
    },
    allLineGroupIds() {
      const resList = _.cloneDeep(this.dispatcherGroupList)
      const driverGroupList = resList.find(v => v.label === "司机客服组")
      if (driverGroupList) {
        return [driverGroupList.id, ...driverGroupList.children.map(v => v.id)].join(",") 
      } else {
        return ""
      }
    }
  },
  computed: {
    optionList() {
      // 根据 filter-value 过滤线路组
      const resList = _.cloneDeep(this.dispatcherGroupList)
      const driverGroupList = resList.find(v => v.label === "司机客服组")
      // 设置司机客服组 ID
      if (this.filterValue != null && driverGroupList) {
        driverGroupList.children = driverGroupList.children.filter(item => {
          return item.portId === this.filterValue
        })
        driverGroupList.value = [driverGroupList.id, ...driverGroupList.children.map(v => v.id)].join(",") || driverGroupList.value
      }
      return resList
    }
  },
  watch: {
    filterValue(v, prev) {
      console.log('port', v)
      if (this.hasInit) {
        this.$emit('input', "ALL")
      }
      // hack: prvent error from element-ui
      if (this.renderFlag) {
        this.renderKey ++
      } else {
        setTimeout(() => {
          this.renderKey ++
          this.renderFlag = true
        }, 300)
      }
      this.hasInit = true
    },
    value: {
      immediate: true,
      handler (v) {
        if (v == 101) {
          const driverGroupList = this.optionList.find(v => v.label === "司机客服组")
          if (driverGroupList && driverGroupList.value !== v) {
            this.$emit('input', String(driverGroupList.value))
          }
          // hack to update text
          if (this.$refs.cascader && this.$refs.cascader.inputValue == null) {
            this.$refs.cascader.inputValue = "司机客服组"
          }
        }
      }
    }
  },
  created() {
    this.initGroupData();
  },
};

</script>

<style>
#dk-accept-group-cascader {
  height: 28px;
}
#dk-accept-group-cascader .label-text {
  float: left;
  width: 80px;
  line-height: 28px;
  text-align: center;
}
#dk-accept-group-cascader .el-cascader--small {
  line-height: 28px;
}

#dk-accept-group-cascader .el-cascader-menu__wrap {
  height: 100%;
}

.accept-group-cascader .el-scrollbar__wrap {
  height: 100%;
}
/*
	以下样式是为了不止点击文字前的小圈圈进行选择，而是可以点击包括文字整行选择。
*/

.accept-group-cascader .el-cascader-panel .el-radio {
    width: 100%;
   	height: 100%;
    z-index: 10;
   	position: absolute;
   	top: 0px;
   	right: 0px; 			
  }
   
 .accept-group-cascader .el-cascader-panel .el-radio__input {
   	margin-top: 10px;
   	margin-left: 8px; 			
 }
   
 .accept-group-cascader .el-cascader-panel .el-cascader-node__postfix {
   		top: 10px; 			
  }
</style>
