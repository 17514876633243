<template>
    <div :style="style" :class="multiple?'dk-search-select multiple':'dk-search-select single'">
        <label :style="{width:labelWidth+'px', 'text-align': Align }">
            <i v-if="isMustFill" class="fa fa-asterisk"></i>
            {{title}}
        </label>
        <div :style="{'marginLeft':'5px'}">
            <el-select
                :clearable="clearable"
                :style="{padding:'0 2px',width:selectWidth+'px'}"
                v-model="value"
                :multiple="multiple"
                filterable
                remote
                :reserve-keyword="reservekeyword"
                :collapse-tags="collapseTags"
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :disabled="isDisabled"
                @change="selectChange"
                @focus="focus"
            >
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item">
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
export default {
    name: "dk-search-select",
    data() {
        return {};
    },
    props: {
        multiple:{
            type: Boolean,
            default: false,
        },
        remoteMethod:{
            type:Function,
            default:function(){}
        },
        focus:{
            type:Function,
            default:function(){}
        },
        reservekeyword:{
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: [],
        },
        value: {
            type: null,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        selectWidth: {
            type: Number,
            default: 250,
        },
        labelWidth: {
            type: Number,
            default: 85,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isMustFill: {
            type: Boolean,
            default: false,
        },
        Align: {
            type: String,
            default: "center",
        },
        style: {
            type: Object,
            default: {
                float:'left'
            },
        },
        clearable: { // 支持不显示clearable，默认true，不会影响以前使用的组件
            type: Boolean,
            default: true,
        },
        collapseTags: { // 多选，省略显示，默认false, 不会影响以前使用的组件
            type: Boolean,
            default: false,
        }
    },
    mounted() {},
    methods: {
        selectChange: function(val) {
            this.$emit('change', val)
        }
    },
};
</script>
<style scope>
    .dk-search-select.multiple .el-tag{
        height: auto!important;
        min-height: 24px!important
    }
    .dk-search-select.multiple .el-select__tags-text{
        white-space: normal!important
    }
    .dk-search-select{
        display:flex;
        flex-direction: row;
        align-items: center;
        margin-bottom:2px
    }
    .single .el-input__inner{
        line-height: 28px!important;
        height: 28px!important;
        /* border: none!important; */
    }
    .single .el-icon-circle-close{
        line-height:100%
    }
    .single .el-input__suffix{
        right:0!important
    }
</style>