<template>
    <div  class="dk-truckmap" :style="styleObjs">
        <div :id="mapId" style="width: 100%;height: 100%;"></div>
        <button @click="setLocation" :class="{'active':locationConfig.isSet}" class="set-location" v-if="location">{{locationConfig.name}}</button>
        <dk-modal :title="setLocationModal.title" :fade="setLocationModal.fade" :style-objs="setLocationModal.style" @change="closeSetModal">
            <div class="bs-example form-group">
                <div class="input-group">
                    <label class="control-label">经纬度</label>
                    <div><input v-model="setLocationModal.value" type="text" class="form-control"></div>
                </div>
            </div>
            <div slot="footer">
                <button type="button" class="btn btn-default negative" @click="closeSetModal">关闭</button>
                <button type="button" class="btn btn-primary negative" @click="comfirmLocation">确认</button>
            </div>
        </dk-modal>
    </div>
</template>
<script>
import dkModal from "dk-modal";
export default {
    name: "dk-truck-map",
    components: {
        "dk-modal": dkModal
    },
    props: {
        objs: {
            type: Object,
            default: {}
        },
        styleObjs: {
            type: Object,
            default: {}
        },
        width:{
            type:String,
            default:"100%"
        },
        height:{
            type:String,
            default:"100%"
        },
        location:{
            type:Boolean,
            default:false
        },
    },
    created: function() {
        this.mapId = "dk-truckmap-box-" + new Date().getTime();
        this.initMap();
    },
    data: function() {
        return {
            mapObj: null,
            portDistrictPoint: "",
            doorAddressPoint: [],
            mapId: "",
            isLoad: true,
            locationConfig:{
                name:"设置经纬度",
                isSet:false,
                marker:null,
                infoWindow:null
            },
            locationName:"",
            point:[],
            setLocationModal: {
                title: '设置经纬度',
                fade: false,
                style: {
                    width: "290px",
                    marginTop: "150px",
                    marginLeft: "auto"
                },
                value: ''
            }
        };
    },
    watch: {
        objs: function() {
            this.locationName = this.objs.locationName ||  '';
            this.point = this.objs.point || [];
            this.$nextTick(this.resetMap);
        }
    },
    methods: {
        //设置经纬度
        setLocation:function(){
            this.locationConfig.name = '设置中...';
            this.locationConfig.isSet = true;
            this.setLocationModal.fade = true;
        },
        closeSetModal:function(){
            this.setLocationModal.fade = false;
            this.locationConfig.name = '设置经纬度';
        },
        //确认获取经纬度
        comfirmLocation:function(){
            if(!this.locationConfig.isSet) return;
            var that = this;
            if(this.setLocationModal.value && this.setLocationModal.value.split(',').length == 2){
                var point = {
                    longitude: this.setLocationModal.value.split(',')[0],
                    latitude: this.setLocationModal.value.split(',')[1]
                };
                var longreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
                if(!longreg.test(point.longitude)){
                    _.ui.notify({
                        message: '经度整数部分为0-180,小数部分为0到6位!',
                        type: 'warning'
                    })
                    return
                }
                var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
                if(!latreg.test(point.latitude)){
                    _.ui.notify({
                        message: '纬度整数部分为0-90,小数部分为0到6位!',
                        type: 'warning'
                    })
                    return
                }
            } else {
                _.ui.notify({
                    message: '经纬度不能为空且要用“,”分割',
                    type: 'warning'
                })
                return
            }
            if(this.objs.pointChange){
                AMap.service('AMap.convertFrom',function(){//回调函数
                    // new AMap.convertFrom(new AMap.LngLat(point.longitude, point.latitude),'gps',function(info,res){
                    //     console.log(res)
                    //     point = {
                    //         longitude: res.locations[0].lng,
                    //         latitude: res.locations[0].lat
                    //     };
                    //     that.settingLocationPoint(point)
                    // })
                    that.changeLngLat(point.longitude,point.latitude,function(point){
                        that.settingLocationPoint(point)
                    })
                })
            }else{
                that.settingLocationPoint(point)
            }
        },
        changeLngLat: function(lng,lat,callback) {
            AMap.service('AMap.convertFrom',function(){//回调函数
                new AMap.convertFrom(new AMap.LngLat(lng, lat),'gps',function(info,res){
                    console.log(res)
                    var point = {
                        longitude: res.locations[0].lng,
                        latitude: res.locations[0].lat
                    };
                    if(callback) callback(point)
                })
            })
        },
        settingLocationPoint:function(point){
            console.log(point);
            this.locationConfig.name = '设置经纬度';
            this.locationConfig.isSet = false;
            this.mapObj.setZoomAndCenter(16, [
                point.longitude,
                point.latitude
            ]);
            if(this.locationConfig.marker){
                this.locationConfig.marker.setPosition(
                    new AMap.LngLat(point.longitude, point.latitude)
                );
            }else{
                this.locationConfig.marker = new AMap.Marker({
                    position: [
                        point.longitude,
                        point.latitude
                    ],
                    icon: new AMap.Icon({
                        size: new AMap.Size(19, 25),
                        image: "../images/OpenLayers/marker_red_sprite.png",
                    }),
                    map: this.mapObj
                });
            }
            if(this.locationName && !this.locationConfig.infoWindow){
                this.locationConfig.infoWindow = new AMap.InfoWindow({
                    content: this.locationName //使用默认信息窗体框样式，显示信息内容
                });
                this.locationConfig.infoWindow.open(this.mapObj, [
                    point.longitude,
                    point.latitude
                ]);
            }else if(this.locationName){
                this.locationConfig.infoWindow.setPosition(
                    new AMap.LngLat(point.longitude, point.latitude)
                );
            }
            this.setLocationModal.fade = false;
            var retPoint = {
                longitude: this.setLocationModal.value.split(',')[0],
                latitude: this.setLocationModal.value.split(',')[1]
            };
            this.$emit("changelocation",retPoint);
            this.setLocationModal.value = '';
        },
        //map 点击获取经纬度
        settingCallback:function(e){
            if(!this.locationConfig.isSet) return;
            var point = {
                longitude: e.lnglat.getLng(),
                latitude: e.lnglat.getLat()
            };
            this.locationConfig.name = '设置经纬度';
            this.locationConfig.isSet = false;
            this.mapObj.setZoomAndCenter(10, [
                point.longitude,
                point.latitude
            ]);
            if(this.locationConfig.marker){
                this.locationConfig.marker.setPosition(
                    new AMap.LngLat(point.longitude, point.latitude)
                );
            }else{
                this.locationConfig.marker = new AMap.Marker({
                    position: [
                        point.longitude,
                        point.latitude
                    ],
                    icon: new AMap.Icon({
                        size: new AMap.Size(19, 25),
                        image: "../images/OpenLayers/marker_red_sprite.png",
                    }),
                    map: this.mapObj
                });
            }
            if(this.locationName && !this.locationConfig.infoWindow){
                this.locationConfig.infoWindow = new AMap.InfoWindow({
                    content: this.locationName //使用默认信息窗体框样式，显示信息内容
                });
                this.locationConfig.infoWindow.open(this.mapObj, [
                    point.longitude,
                    point.latitude
                ]);
            }else if(this.locationName){
                this.locationConfig.infoWindow.setPosition(
                    new AMap.LngLat(point.longitude, point.latitude)
                );
            }
            this.$emit("changelocation",point);
        },
        getLocationName:function(){
            if(!this.locationName) return;
            var that = this;
            AMap.service('AMap.Geocoder',function(){//回调函数
                //实例化Geocoder
                var geocoder = new AMap.Geocoder();
                geocoder.getLocation(that.locationName, function(status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        var point = result.geocodes[0].location;
                        var data ={
                            longitude:point.lng,
                            latitude:point.lat
                        } 
                        that.point = [point.lng,point.lat];
                        that.getSetPointMark();
                        that.$emit("changelocation",data);
                    }else{
                        that.$emit("changelocation",null);
                    }
                });
            })

        },
        setMap: function() {
            console.log('setmap',this.objs)
            var data = this.objs,
                that = this,
                requestMax = 0,
                requestNum = 0;
            if (!data.plateNumber) {
                console.warn('车牌号为空');
                return;
            }
            _.ui.mask({ ele: "#" + this.mapId,width:'300px' });
            var callback = function() {
                if (requestNum < requestMax) return;
                //获取车牌号
                that.getPlateNumber(function(){
                    _.ui.unmask({ ele: "#" + that.mapId });
                });
            }
            //获取港区
            if (data.portDistrict) {
                requestMax++;
                this.getPortDistrict(function() {
                    requestNum++;
                    callback();
                });
            }
            //获取门点
            if (data.doorAddress && !data.doorLocation) {
                that.doorAddressPoint = [];
                var that = this;
                var doorAddresses = data.doorAddress.split(';');
                doorAddresses.forEach(function(elem, index){
                    requestMax++;
                    that.getDoorLocation(index, (elem || '').trim(),function(res){
                        if(res) {
                            requestNum++;
                            callback();
                        }else{
                            that.getAdress((elem || '').trim(), function(point) {
                                requestNum++;
                                that.doorAddressPoint[index] = point;
                                callback();
                            });
                        }
                    });
                });
            }
            if(data.doorLocation){
                this.doorAddressPoint[0] = data.doorLocation;
            } 
            callback();
        },
        getDoorLocation:function(index, areaAddress,callback){
            var that = this;
            _.util.ajax_get('/trade/company/location/get',{areaAddress:areaAddress},function(res){
                if(res.success){
                    var point = {
                        lat: res.obj.lat,
                        lng: res.obj.lng
                    };
                    that.doorAddressPoint[index] = point;
                    that.addMarker(point,areaAddress);
                }
                if(callback) callback(res.success);
            },function(){
                if(callback) callback(false);
            });
        },
        getPlateNumber: function(callback) {
            var plateNumber = this.objs.plateNumber || '',
                that = this;
            _.util.ajax_get('/zhiyun/api/findCarNo', {
                vclN: plateNumber.trim(),
            }, function(res) {
                console.log('地图数据',res)
                if (!res.success) {
                    if(callback) callback();
                    return;
                };
                
                var lat = res.carInfo.lat //纬度
                var lon = res.carInfo.lon //经度
                var adr = res.carInfo.adr
                var point = "";
                var convertCallback = function(point) {
                    var callbackMaxNum = 0,
                        backNum = 0,
                        convertLocation = null,
                        portDistanceValue = "",
                        depotDistanceValue = "",
                        doorDistanceValue = [];
                    var done = function() {
                        if (backNum < callbackMaxNum) return;
                        if(callback) callback();
                        var data = {
                            drc: res.carInfo.drc || 0,
                            plateNumber: that.objs.plateNumber,
                            // time: _.util.date_to_string(new Date(Number(res.carInfo.utc)), null, true),
                            time: res.carInfo.utc,
                            speed: res.carInfo.spd + 'km/h',
                            direction: res.carInfo.direction,
                            position: point,
                            portDistrictDistance: portDistanceValue,
                            firstDoorAreaDistance: doorDistanceValue[0],
                            secondDoorAreaDistance: doorDistanceValue[1] || '',
                            doorAreaDistance:doorDistanceValue[0],
                            planGateInTime:that.objs.planGateInTime,
                            closeTime:that.objs.closeTime,
                            planPickUpTime:that.objs.planPickUpTime,
                            eirEffectiveTime:that.objs.eirEffectiveTime,
                            documentOffTime:that.objs.documentOffTime,
                            gongchang:that.objs.gongchang,
                            depotDistance:depotDistanceValue,
                            depot:that.objs.depot,
                        };
                        that.$emit("done", data)
                        that.addDialog([lon,lat], point, data, res.carInfo.drc);
                    }
                    //计算港区距离
                    if (that.portDistrictPoint) {
                        callbackMaxNum++;
                        that.getRout([lon,lat], [that.portDistrictPoint.lng,that.portDistrictPoint.lat], function(portDistance) {
                            portDistanceValue = portDistance;
                            backNum++;
                            done();
                        });
                    }
                    //计算堆场距离
                    if (that.objs.depot) {
                        callbackMaxNum++;
                        that.getRout([lon,lat], [that.objs.lng,that.objs.lat], function(portDistance) {
                            depotDistanceValue = portDistance;
                            that.addMarker(that.objs, that.objs.depot)
                            backNum++;
                            done();
                        });
                    }
                    //计算门点距离
                    if(that.doorAddressPoint.length > 0){
                        that.doorAddressPoint.forEach(function(elem, index){
                            if (elem) {
                                callbackMaxNum++;
                                that.getRout([lon,lat], [elem.lng,elem.lat], function(doorDistance) {
                                    doorDistanceValue[index] = doorDistance;
                                    backNum++;
                                    done();
                                });
                            }
                        });
                    }
                    done();
                }
                that.convertPoint(lon, lat, adr, convertCallback);

            },function(){
                if(callback) callback();
            });
        },
        getPortDistrict: function(callback) {
            // add location for port district
            var portDistrict = this.objs.portDistrict || '',
                that = this;
            _.util.ajax_get('/zhiyun/api/convertPoint', {
                portDistrict: portDistrict.trim()
            }, function(res) {
                if (res && res.success) {
                    var point = {
                        lat: res.carInfo.lat,
                        lng: res.carInfo.lon
                        
                    };
                    that.portDistrictPoint = point;
                    that.addMarker(point, portDistrict)
                }
                if (callback) callback();
            },function(){
                if(callback) callback();
            })
        },
        addMarker: function(point, address) {
            var marker1 = new AMap.Marker({
                position: [point.lng,point.lat],
                icon: new AMap.Icon({
                    size: new AMap.Size(19, 25),
                    image: "../images/OpenLayers/marker_red_sprite.png",
                }),
            });
            marker1.setMap(this.mapObj);
            var marker = new AMap.Marker({
                position: [point.lng,point.lat],
                content: '<div style="background-color:#FFF;padding: 1px 3px;white-space: nowrap;margin-top: -20px;margin-left: -20px;">'+address+'</div>'
            });
            marker.setMap(this.mapObj);
        },
        initMap: function() {
            const oldscript = Array.from(document.getElementsByTagName("script")).find((script) => {
                const src = script.getAttribute("src");
                return src && src.startsWith("https://webapi.amap.com/maps?v=1.4.2&key=47be8e6664c78cfdbc3a8172cbcb1106&callback=truckMapCallback");
            });
            if (oldscript) {
                // 已加载依赖
                this.isLoad = false;
                this.resetMap();
                return;
            }
      
            var script = document.createElement("script"),
                that = this;
            script.type = "text/javascript";
            script.src = "https://webapi.amap.com/maps?v=1.4.2&key=47be8e6664c78cfdbc3a8172cbcb1106&callback=truckMapCallback";
            document.head.appendChild(script);

            window.truckMapCallback = function() {
                if(window.truckMapCallback && !that.isLoad)return;
                that.isLoad = false;
                that.resetMap();
            }
        },
        //刷新地图信息
        resetMap: function() {
            var sizeStore = 0;
            if(window.localStorage) {	
            // 遍历所有存储	
                for(var item in window.localStorage) {
                if(window.localStorage.hasOwnProperty(item)) {
                        sizeStore += window.localStorage.getItem(item).length;
                    }
                }
            }
            console.log((sizeStore / 1024 / 1024).toFixed(2) + 'M');
            for (var key in localStorage){
                if (key.indexOf("_AMap") != -1){
                    localStorage.removeItem(key)
                }
            }
            this.locationConfig.name = '设置经纬度';
            this.locationConfig.isSet = false;
            this.locationConfig.infoWindow = null;
            this.locationConfig.marker = null;
            var that = this;
            that.mapObj = new AMap.Map(this.mapId,{
                zoom: 10, //默认缩放大小
                center: [121.48, 31.22], //默认中心点
                doubleClickZoom: false //禁止双击地图缩放
            });
            AMap.plugin(['AMap.ToolBar','AMap.Scale','AMap.OverView'],
                function(){
                    that.mapObj.addControl(new AMap.ToolBar());
                    that.mapObj.addControl(new AMap.Scale());
            });
            // 创建交通流量图层实例
            var trafficLayer = new AMap.TileLayer.Traffic({
                zIndex: 10
            });
            trafficLayer.setMap(that.mapObj);
            // this.mapObj.on('click', this.settingCallback);
            this.getSetPointMark();
            if(!(this.point.length && this.locationName)){
                this.getLocationName();
            }
            // 创建交通流量图层实例
            this.setMap();
        },
        getSetPointMark:function(){
            if(!this.point.length) return;
            this.locationConfig.marker = new AMap.Marker({
                position: this.point,
                icon: new AMap.Icon({
                    size: new AMap.Size(19, 25),
                    image: "../images/OpenLayers/marker_red_sprite.png",
                }),
                map: this.mapObj
            });
            this.locationConfig.infoWindow = new AMap.InfoWindow({
                content: this.locationName //使用默认信息窗体框样式，显示信息内容
            });
            this.locationConfig.infoWindow.open(this.mapObj, this.point);
            
        },
        //根据地址获取经纬度
        getAdress: function(address, callback) {
            if (!address) return;
            var geocoder,that = this;
            AMap.service('AMap.Geocoder',function(){//回调函数
                //实例化Geocoder
                geocoder = new AMap.Geocoder();
                geocoder.getLocation(address, function(status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        var point = result.geocodes[0].location;
                        that.addMarker(point, address);
                        if (callback) callback(point);
                    }else{
                        if (callback) callback(null);
                    }
                });
            })
        },
        convertPoint: function(lon, lat, adr, callback) {
            if(callback) callback(adr);
            // var geocoder;
            // var that = this;
            //     AMap.service('AMap.Geocoder',function(){//回调函数
            //         //实例化Geocoder
            //         geocoder = new AMap.Geocoder({
            //             adius: 1000,
            //             extensions: "all"
            //         });
            //         geocoder.getAddress([lon,lat], function(status, result) {
            //             if (status === 'complete' && result.info === 'OK') {
            //                 if(callback) callback(result.regeocode.formattedAddress);
            //             }
            //         });  
            //     }) 
        },
        //弹出信息窗口
        addDialog: function(point, address, message, drc) {
            var that = this;
            var icon = new AMap.Icon({
                image : '../images/icon_back_car.png',
                size : new AMap.Size(55,45)
            });
            var marker = new AMap.Marker({
                icon : icon,
                position : point,
                offset : new AMap.Pixel(-12,-12),
                map : this.mapObj,
                angle: parseInt(drc) + 180
            });
            marker.setMap(this.mapObj);
            var html = "",
                viewAttrName = {
                    riskState:"异常状态",
                    plateNumber: "车牌号：",
                    time: "时间：",
                    speed: "速度：",
                    direction: "方向：",
                    position: "位置：",
                    firstDoorAreaDistance: "门点距离：",
                    secondDoorAreaDistance: "门点二距离：",
                    planGateInTime: "预计进港时间：",
                    closeTime: "截港时间：",
                    portDistrictDistance: "港区距离：",
                    planPickUpTime: "预计提箱时间：",
                    eirEffectiveTime: "EIR失效时间：",
                    documentOffTime: "截单时间：",
                    gongchang: "工厂下班时间：",
                    depot: "堆场：",
                    depotDistance: "堆场距离：",
                };
            for (var i in message) {
                if (message[i] && viewAttrName[i]) {
                    if (viewAttrName[i] == "异常状态"){
                        html += '<div style="color: red;text-align:center;border-bottom:1px solid #ccc;line-height: 34px;">' + message[i] + '</div>';
                        continue;
                    }
                    if (viewAttrName[i] == "时间：" && (new Date()).getTime() - (new Date(message[i])).getTime() > 3600000){
                        html += '<div style="color: #FF3030;">' + viewAttrName[i] + message[i] + '(暂无北斗信号)</div>';
                        continue;
                    }
                    html += '<div>' + viewAttrName[i] + message[i] + '</div>';
                }
            }
            var dialog = new AMap.InfoWindow({
                content: html  //使用默认信息窗体框样式，显示信息内容
            });
            dialog.open(this.mapObj, point);
            marker.on('click', function(){
                dialog.open(that.mapObj, point);
            });
        },
        getRout: function(dpoint, spoint, callback) {
            //计算距离
            AMap.service('AMap.Driving',function(){//回调函数
                //实例化Driving
                var driving= new AMap.Driving({city: '上海'});
                driving.search(dpoint, spoint, function(status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        var distance = result.routes[0].distance;
                        distance = (parseFloat(distance) / 1000).toFixed(2);
                        distance += "公里";
                        if(callback) callback(distance)
                        return;
                    }
                    if(callback) callback(null)
                })
            })
        }
    }
};
</script>

